import React, { FunctionComponent, useRef, useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
} from '@material-ui/core';
import PersonIcon from '@mui/icons-material/Person';
import LatestBackground from "../../Assets/Images/login_banner.svg";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from "react-redux";
import { showSnackbarAction } from "../../Redux/actions";
import { autoFacebookCreateApi, createOrganization, fetchNews } from "../../Services/organizations";
import axios from "axios";
import { countryData, url } from "../../Values/constants"
import { createAutoFAQ, createAutoNews, createBudgetAuto, createCurouseAuto, createLeadSourceAuto, createLocationAuto, createPropertyStageAuto, createPropertySubTYpeAuto, createPropertyTypesAuto, createTrasferReasonsAuto, fetchFAQAdmin, fetchLicensesCost, fetchResources } from "../../Services/resources";
import { Icon } from "@mui/material";
import BusinessIcon from '@mui/icons-material/Business';
import LeadRubixLogo from "../../Assets/Images/leadrubixlogo.png";
import { Circles, Audio, BallTriangle } from 'react-loader-spinner';

const useStyles = makeStyles((theme) => ({
  outercontainer: {
    // padding: theme.spacing(2),
    // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundImage: `url(${LatestBackground})`, // Replace with the URL of your image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  },
  root: {
    marginTop: "4%",
  },
  formContainer: {
    padding: theme.spacing(2),
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', // Add box shadow
    borderRadius: "5%",
    background: "#363853"
    // background: "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(130,4,4,0.87718837535014) 51%, rgba(0,151,250,0.3561799719887955) 100%)"
  },
  selectBox: {
    minWidth: 225,
    background: "white",
    borderRadius: '5px',
    paddingLeft: '10px', // This will add padding to the left of the input field
    '& .MuiInputLabel-root': {
      paddingLeft: '10px', // This will add padding to the left of the label
    },
    '& .MuiInputBase-input::placeholder': {
      paddingLeft: '10px', // This will add padding to the left of the input field placeholder
    },
  },
  selectInput: {
    height: '2px', // Adjust the height as per your requirement
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    height: "100%"
  },
  image: {
    width: '100%',
    marginTop: "10%",
    // maxWidth: 400,
    height: '100%',
  },
  logoimage: {
    width: '70%',
    marginTop: "1%",
    // maxWidth: 400,
    height: '50px',
  },
  // inputFeilds:{
  //   backgroundColor:'white',
  //   color:'black',
  //   borderRadius:'5px',
  //   // paddingLeft:"10px"
  //   '::placeholder': {
  //     paddingLeft: '10px', // This will add padding to the left of the placeholder
  //   },
  // }
  inputFeilds: {
    outline: "none",
    backgroundColor: 'white',
    color: 'black',
    borderRadius: '5px',
    paddingLeft: '10px', // This will add padding to the left of the input field
    '& .MuiInputLabel-root': {
      paddingLeft: '10px', // This will add padding to the left of the label
    },
    '& .MuiInputBase-input::placeholder': {
      paddingLeft: '10px', // This will add padding to the left of the input field placeholder
    },
  },
}));

interface CountryObj {
  label: string;
  value: string;
  // Other properties, if any
}

type props = {
  history: any;
};
const RegistrationForm: FunctionComponent<props> = ({ history }) => {
  const dispatcher = useDispatch();
  const classes = useStyles();
  const [formData, setFormData] = useState({
    orgAddress: '',
    numberOfEmployees: '',
    organizationName: '',
    email: '',
    mobileNumber: '',
    pincode: '',
    adminEmail: '',
    adminFirstName: '',
    adminLastName: ''
  });
  const [phoneCountryCode, setPhoneCountryCode] = useState('in');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [finalPhoneNumber, setFinalPhoneNumber] = useState('');
  const [adminPhoneCountryCode, setAdminPhoneCountryCode] = useState('in');
  const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
  const [adminFinalPhoneNumber, setAdminFinalPhoneNumber] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState<object>({});
  const [statesList, setStatesList] = useState([]);
  const [state, setState] = useState<object>({});
  const [citiesList, setCitiesList] = useState([]);
  const [cities, setCities] = useState<object>({});
  const [load, setLoad] = useState(false);
  const [booleanSendEmail, setBooleanSendEmail] = useState(false);
  const [pwd, setPwd] = useState("");
  const [industry, setIndustry] = useState<object>({});
  const [orgId, setOrgId] = useState();
  const [authId, setAuthId] = useState();
  const [cost, setCost] = useState<any>("");
  const [industryList, setIndustryList] = useState([]);
  const [noEmp, setNoEmp] = useState<any>("");
  const [costLicensesData, setCostLicensesData] = useState<any[] | undefined>(undefined);
  const [faqList, setfaqList] = useState<any[] | undefined>(undefined);
  const [news, setNews] = useState<any[] | undefined>(undefined);
  const [comTypes, setComTypes] = useState<any[]>([]);
  const [resTypes, setResTypes] = useState<any[]>([]);
  const [transferReasons, setTransferReasons] = useState<any[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<any[]>([]);
  const [propertyStage, setPropertyStage] = useState<any[]>([]);
  const [propertySubType, setPropertySubType] = useState<any[]>([]);
  const [leadSourceList, setLeadSourceList] = useState<any[]>([]);
  const [budgetsList, setBudgetsList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [carouselList, setCarouselList] = useState<any[] | undefined>(
    undefined
  );

  useEffect(() => {
      const unsub = fetchResources(
        (data) => setLocationsList(data),
        (data) => setBudgetsList(data),
        (data) => setCarouselList(data),
        (data) => setLeadSourceList(data),
        (data) => setComTypes(data),
        (data) => setResTypes(data),
        " ",
        (data) => setTransferReasons(data),
        (data) => setPropertyTypes(data),
        (data) => setPropertyStage(data),
        (data) => setPropertySubType(data),
      );
      return () => {
        unsub();
      };
  }, []);
 useEffect(()=>{
  fetchNews(" ", (val) => setNews(val));
 },[])
  useEffect(() => {
    fetchFAQAdmin((data) => setfaqList(data));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryRes = await axios.post(
          `${url}/country/countries`);
        // handle response
        setCountryList(countryRes.data);
      } catch (error) {
        // handle error
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(country).length !== 0) {
      const fetchData = async () => {
        try {
          const statesRes = await axios.post(
            `${url}/country/states`, { iso3: (country as any).value });
          // handle response
          setStatesList(statesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();

    }
  }, [country]);

  useEffect(() => {
    if (Object.keys(country).length !== 0 && Object.keys(state).length !== 0) {
      const fetchData = async () => {
        try {
          const citiesRes = await axios.post(
            `${url}/country/cities`, { iso3: (country as any).value, state_code: (state as any).value });
          // handle response
          setCitiesList(citiesRes.data);
        } catch (error) {
          // handle error
        }
      };
      fetchData();
    }
  }, [country, state])

 
  useEffect(() => {
    if (booleanSendEmail) {
      sendResetPasswordEmail();
      createOrganizationDb();
      autoFacebookCreateApi(
        orgId,
        "Facebook",
        // (country as any).value,
        adminPhoneCountryCode == "in" ? "+91" :`+${adminPhoneCountryCode}`,
        formData.adminEmail.toLowerCase(),
        adminPhoneCountryCode == "in" ? 91 : parseInt(adminPhoneCountryCode),
        adminFinalPhoneNumber,
        formData.adminFirstName,
        formData.adminLastName,
      );
      createResources();
      createFAQ();
      createNew();
    }
  }, [booleanSendEmail]);

  useEffect(() => {
    setCost(costLicensesData?.[0]?.licensesCost);
    setNoEmp(costLicensesData?.[0]?.no_employees);
  }, [costLicensesData]);

  useEffect(() => {
    fetchLicensesCost((data) => setCostLicensesData(data));
  }, [])

  const createFAQ = () => {
    createAutoFAQ(
      orgId,
       faqList
    )
  };
  const createNew=()=>{
    createAutoNews(
      orgId,
      news
    )
  }

  const createResources=()=>{
    createLocationAuto(
      orgId,
      locationsList
    )
    createBudgetAuto(
      orgId,
      budgetsList
    )
    createCurouseAuto(
      orgId,
      carouselList
    )
    createLeadSourceAuto(
      orgId,
      leadSourceList
    )
    createPropertyStageAuto(
      orgId,
      propertyStage
    )
    createPropertyTypesAuto(
      orgId,
      propertyTypes
    )
    createPropertySubTYpeAuto(
      orgId,
      propertySubType
    )
    createTrasferReasonsAuto(
      orgId,
      transferReasons
    )
  }
  const createOrganizationDb = async () => {
    const bodyData = {
      organization_id: orgId,
      address: formData.orgAddress,
      admin_contact_number: adminFinalPhoneNumber,
      admin_email_id: formData.adminEmail.toLowerCase(),
      admin_first_name: formData.adminFirstName,
      admin_last_name: formData.adminLastName,
      auth_id: authId,
      city: (cities as any).label,
      country: (country as any).label,
      created_at: "",
      designations: [],
      email_id: formData.email.toLowerCase(),
      mobile_number: finalPhoneNumber,
      no_of_employees: formData.numberOfEmployees,
      organization_name: formData.organizationName,
      state: (state as any).label,
      status: "ACTIVE",
      teams: [],
      industry_id: (industry as any).value,
      payment_status: true,
      grace_period: 7,
      showData: true,
      trial_period: true,
      cost_per_license: cost,
      org_trial_period_users_licenses: noEmp,
    }
    const createOrg = await axios.post(
      url + '/organizations/create', bodyData
    );
  }

  const sendResetPasswordEmail = async () => {
    let email = formData.adminEmail.toLowerCase()
    let firstName = formData.adminFirstName;
    let lastName = formData.adminLastName;
    if (email !== "") {
      try {
        if (email) {
          setLoad(true);
          const resetPasswordEmailApiData = {
            "template_name": "Password_Reset_Notification",
            "replaceable_params": [
              { "%#username#%": `${firstName} ${lastName}` },
              { "%#password#%": pwd }
            ],
            "email": email
          }
          await axios.post(
            url + '/email/sendEmail',
            resetPasswordEmailApiData
          );
          setLoad(false);
          dispatcher(
            showSnackbarAction(
              "Password reset information have been sent on you registered email id",
              "success"
            )
          );
          history.push("/");
        }
      } catch (error) {
        console.log("reset error", error);
      }
    } else {
      dispatcher(showSnackbarAction("Please Enter Your Email!", "error"));
    }
  }

  const choice = () => {
    let optionArr = statesList?.map((list: { state_code: any, name: any }) => ({
      value: list.state_code,
      label: list.name
    }));
    return optionArr;
  };

  const countryChoice = () => {
    let optionArr = countryList?.map((list: { iso3: any, name: any, phone_code: any }) => ({
      value: list.iso3,
      label: `${list.name}`
      // label: `+${list.phone_code} ${list.name}`
    }));
    return optionArr;
  };

  const citiesChoice = () => {
    let optionArr = citiesList?.map((list: { name: any }) => ({
      value: list.name,
      label: list.name
    }));
    return optionArr;
  };

  const choiceIndustry = () => {
    let optionArr = industryList?.map((list: { industry_id: any, industry_name: any }) => ({
      value: list.industry_id,
      label: list.industry_name
    }));
    return optionArr;
  }

  const handleCountryChange = (event: { target: { value: any; }; }) => {
    setCountry(JSON.parse(event.target.value));
  };

  const handleStateChange = (event: { target: { value: any; }; }) => {
    setState(JSON.parse(event.target.value));
  };

  const handleCitiesChange = (event: { target: { value: any; }; }) => {
    setCities(JSON.parse(event.target.value));
  };

  const handleChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handlePhoneChange = (value: string, country: CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setFinalPhoneNumber(value);
    }
    setPhoneCountryCode(country?.dialCode);
    setPhoneNumber(value)
  };

  useEffect(() => {
    getIndustry();
  }, []);

  const getIndustry = async () => {
    const industryRes = await axios.post(
      url + '/headers/getAllIndustries',
      {
        "status": "Launched"
      }
    );
    setIndustryList(industryRes.data);
  }

  const handleIndustryChange = (event: { target: { value: any; }; }) => {
    setIndustry(JSON.parse(event.target.value));
  };

  const adminHandlePhoneChange = (value: string, country: CountryData) => {
    // Extract the country code and phone number from the input value
    if (value.startsWith(country?.dialCode)) {
      const phoneNumberWithoutCode = value.substring(country?.dialCode.length);
      setAdminFinalPhoneNumber(phoneNumberWithoutCode);
    } else {
      setAdminFinalPhoneNumber(value);
    }
    setAdminPhoneCountryCode(country?.dialCode);
    setAdminPhoneNumber(value)
  };

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    // You can handle form submission here (e.g., send data to the server)
    const numberPattern = /^[0-9]+$/;
    if (formData.organizationName === "") {
      dispatcher(
        showSnackbarAction(
          "Organization Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.numberOfEmployees === "") {
      dispatcher(
        showSnackbarAction(
          "No of Employees Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.email === "") {
      dispatcher(
        showSnackbarAction(
          "Email Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.orgAddress === "") {
      dispatcher(
        showSnackbarAction(
          "Address Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(country).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Country Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(state).length === 0) {
      dispatcher(
        showSnackbarAction(
          "State Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(cities).length === 0) {
      dispatcher(
        showSnackbarAction(
          "City Required!!",
          "error"
        )
      );
      return;
    }
    else if (Object.keys(industry).length === 0) {
      dispatcher(
        showSnackbarAction(
          "Industry Id Required!!",
          "error"
        )
      );
      return;
    }
    else if (finalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.pincode === "") {
      dispatcher(
        showSnackbarAction(
          "Pincode Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.adminFirstName === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Name Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.adminLastName === "") {
      dispatcher(
        showSnackbarAction(
          "Admin First Last Required!!",
          "error"
        )
      );
      return;
    }
    else if (adminFinalPhoneNumber === "") {
      dispatcher(
        showSnackbarAction(
          "Admin Mobile Number Required!!",
          "error"
        )
      );
      return;
    }
    else if (formData.adminEmail === "") {
      dispatcher(
        showSnackbarAction(
          "Admin Email Required!!",
          "error"
        )
      );
      return;
    }
    setLoad(true);
    createOrganization(
      formData.organizationName,
      finalPhoneNumber,
      phoneCountryCode == "in" ? 91 : parseInt(phoneCountryCode),
      formData.email,
      formData.orgAddress,
      (country as any).label,
      (country as any).value,
      (state as any).label,
      (state as any).value,
      (cities as any).label,
      (cities as any).value,
      parseInt(formData.pincode),
      formData.adminFirstName,
      formData.adminLastName,
      adminFinalPhoneNumber,
      adminPhoneCountryCode == "in" ? 91 : parseInt(adminPhoneCountryCode),
      formData.adminEmail,
      parseInt(formData.numberOfEmployees),
      history,
      dispatcher,
      window,
      (data: boolean) => setLoad(data),
      (data: boolean) => setBooleanSendEmail(data),
      (data: any) => setPwd(data),
      (industry as any).value,
      (data: any) => setOrgId(data),
      (data: any) => setAuthId(data),
      cost,
      noEmp
    );
  };

  return (
    // <div className={classes.outercontainer}>
    <Container maxWidth="lg" className={classes.root}>
      <Grid container spacing={1} sm={12}>
        <Grid item xs={12} sm={7} className={classes.imageContainer}>
          <img
            src={LatestBackground} // Replace with the URL of your image
            alt="Registration"
            className={classes.image}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Paper elevation={3} className={classes.formContainer}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={LeadRubixLogo} // Replace with the URL of your image
                alt="CompanyLogo"
                className={classes.logoimage}
              />
            </Grid>
            <Typography variant="h5" align="center" style={{ color: 'white' }} gutterBottom>
              Registration Form
            </Typography>
            <form onSubmit={handleSubmit} >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Organization Name*"
                    fullWidth
                    name="organizationName"
                    value={formData.organizationName}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Number of License User*"
                    fullWidth
                    name="numberOfEmployees"
                    value={formData.numberOfEmployees}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email Id*"
                    type="email"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Enter Address*"
                    fullWidth
                    name="orgAddress"
                    value={formData.orgAddress}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    className={classes.selectBox}
                    label="Country*"
                    variant="standard"
                    name="country"
                    // value={country}
                    onChange={handleCountryChange}
                    InputProps={{ classes: { input: classes.selectInput }, style: { height: 33 } }}
                  >
                    <MenuItem value="">
                      Country (Select one)
                    </MenuItem>
                    {countryChoice().map((country, index) => (
                      <MenuItem value={JSON.stringify(country)}>{country.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    className={classes.selectBox}
                    label="State*"
                    variant="standard"
                    name="state"
                    onChange={handleStateChange}
                    InputProps={{ classes: { input: classes.selectInput }, style: { height: 33 } }}
                  >
                    <MenuItem value="">
                      State (Select one)
                    </MenuItem>
                    {choice().map((state, index) => (
                      <MenuItem value={JSON.stringify(state)}>{state.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    className={classes.selectBox}
                    label="City*"
                    variant="standard"
                    name="city"
                    onChange={handleCitiesChange}
                    InputProps={{ classes: { input: classes.selectInput }, style: { height: 33 } }}
                  >
                    <MenuItem value="">
                      City (Select one)
                    </MenuItem>
                    {citiesChoice().map((city, index) => (
                      <MenuItem value={JSON.stringify(city)}>{city.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    className={classes.selectBox}
                    label="Industry*"
                    variant="standard"
                    name="industry"
                    onChange={handleIndustryChange}
                    InputProps={{ classes: { input: classes.selectInput }, style: { height: 33 } }}
                  >
                    <MenuItem value="">
                      Industry (Select one)
                    </MenuItem>
                    {choiceIndustry().map((indutry, index) => (
                      <MenuItem value={JSON.stringify(indutry)}>{indutry.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  {/* Phone input with country flag */}
                  <PhoneInput
                    country={phoneCountryCode} // Set default country (e.g., United States)
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder='Mobile Number'
                    inputStyle={{ width: '100%', height: "48px" }}
                    containerStyle={{ height: "48px" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Enter Pincode*"
                    fullWidth
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Admin First Name*"
                    fullWidth
                    name="adminFirstName"
                    value={formData.adminFirstName}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Admin Last Name*"
                    fullWidth
                    name="adminLastName"
                    value={formData.adminLastName}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Admin Email Id*"
                    type="email"
                    fullWidth
                    name="adminEmail"
                    value={formData.adminEmail}
                    onChange={handleChange}
                    className={classes.inputFeilds}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* Phone input with country flag */}
                  <PhoneInput
                    country={adminPhoneCountryCode} // Set default country (e.g., United States)
                    value={adminPhoneNumber}
                    onChange={adminHandlePhoneChange}
                    placeholder='Mobile Number'
                    inputStyle={{ width: '100%', height: "48px" }}
                    containerStyle={{ height: "48px" }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    // color="primary"
                    style={{ backgroundColor: "#272944", color: 'white' }}
                    startIcon={<BusinessIcon />}
                  // fullWidth
                  >
                    {load ?
                      <Circles
                        color="#fff" height={20} width='100%' /> : 'Register'}
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div
                    onClick={() => history.push("/")}
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      padding: '8px 12px',
                      backgroundColor: '#f2f2f2',
                      borderRadius: '5px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <p style={{ marginBottom: "0", fontSize: "14px", color: "black" }}>
                      Already have an account?
                    </p>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    // </div>
  );
};

export default RegistrationForm;
