import Firebase from "firebase/app";
import { firestore, functions } from "../Firebase";
import { showSnackbarAction } from "../Redux/actions";
import { admin_mail, country_code } from "../Values/constants";
import { addDays, generatePassword } from "../Values/utils";

let organizationId = "";


export const countActiveUsersByOrganization = async( 
  setActiveUserCount:(data:any) => void,
  organizationId:any)=> {
  try {
    const usersCollection = firestore.collection('users');
    const querySnapshot = await usersCollection
      .where('organization_id', '==', organizationId)
      .where('status', '==', 'ACTIVE')
      .get();
    const activeUsersCount = querySnapshot?.size;
     setActiveUserCount(activeUsersCount);
    console.log(`Number of active users in organization ${organizationId}: ${activeUsersCount}`);
    return activeUsersCount;
  } catch (error) {
    console.error('Error counting active users: ', error);
  }
}


export const fetchOrganizations = (
  setData: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("organizations")
    .onSnapshot((organizations) => {
      const data: any[] = [];
      if (organizations) {
        organizations.forEach((organization) => {
          data.push({
            ...organization.data(),
            organization_id: organization.id,
          });
        });
        data.sort((a, b) => {
          return b.created_at.seconds - a.created_at.seconds;
        });
        let filterData = data.filter((list: any) => list.organization_name != "Optiinvest")
        setData(filterData);
      } else {
        setData([]);
      }
    });
  return subscriber;
};

export const createOrganization = (
  organization_name: string,
  mobile_number: string,
  organization_mobile_code: number,
  email_id: string,
  address: string,
  country: string,
  country_code: string,
  state: string,
  state_code: string,
  city: string,
  city_code: string,
  pincode: number,
  admin_first_name: string,
  admin_last_name: string,
  admin_contact_number: string,
  admin_contact_code: number,
  admin_email_id: string,
  no_of_employees: number,
  history: any,
  dispatcher: any,
  window: any,
  setLoad: (data: boolean) => void,
  setBooleanSendEmail: (data: boolean) => void,
  setPwd: (data: any) => void,
  industry: any,
  setOrgId: (data: any) => void,
  setAuthId: (data: any) => void,
  costPerLicense: any,
  noEmp: any,
) => {
  let password = generatePassword(9);
  setPwd(password);
  const org_id = firestore
    .collection("organizations")
    .doc();
  setOrgId(org_id.id);
  functions
    .httpsCallable("createOrganization")(
      JSON.stringify({
        email: admin_email_id.toLowerCase(),
        password: password,
        displayName:
          admin_first_name + " " + admin_last_name,
        organization_id: org_id.id,
      })
    )
    .then((response) => {
      let currentDate = new Date();
      let validTill = addDays(currentDate, 7);
      setAuthId(response.data);
      org_id
        .set(
          {
            organization_name: organization_name,
            mobile_number: mobile_number,
            organization_mobile_code: organization_mobile_code,
            email_id: email_id.toLowerCase(),
            address: address,
            country: country,
            country_code: country_code,
            state: state,
            state_code: state_code,
            city: city,
            city_code: city_code,
            industry_id: industry,
            created_at: Firebase.firestore.Timestamp.now(),
            pincode: pincode,
            admin_first_name: admin_first_name,
            admin_last_name: admin_last_name,
            admin_contact_number: admin_contact_number,
            admin_contact_code: admin_contact_code,
            admin_email_id: admin_email_id.toLowerCase(),
            no_of_employees: no_of_employees,
            teams: [],
            // visibility:"true",
            designations: [],
            showAnalytics: true,
            trial_period: true,
            status: "ACTIVE",
            allowDuplicateLeads: true,
            auth_id: response.data,
            cost_per_license: costPerLicense,
            org_trial_period_users_licenses: noEmp,
            valid_from: new Date(),
            valid_till: validTill,
            payment_status: true,
            grace_period: 7,
            showData: true
          },
          { merge: true }
        )
        .then(() => {
          firestore
            .collection("users")
            .doc(response.data)
            .set({
              user_first_name: admin_first_name,
              user_last_name: admin_last_name,
              user_email: admin_email_id.toLowerCase(),
              team: "",
              reporting_to: "",
              contact_no: admin_contact_number,
              admin_contact_code: admin_contact_code,
              designation: "Organization Admin",
              status: "ACTIVE",
              organization_id: org_id.id,
              created_by: "Super Admin",
              created_at:
                Firebase.firestore.Timestamp.now(),
              profile: "Admin",
              uid: response.data,
              user_image: "",
              device_id: "",
              country: country,
              country_code: country_code,
              state: state,
              no_of_employees: no_of_employees,
              cost_per_license: costPerLicense,
              valid_from: new Date(),
              valid_till: validTill,
              payment_status: true,
              grace_period: 7,
              showData: true,
              trial_period: true,
            });
          firestore
            .collection("organizationResources")
            .doc(org_id.id)
            .set({
              organization_id: org_id.id,
              permission: {
                "Sales": [
                  "Budget",
                  "Contact No.",
                  "Created At",
                  "Customer Name",
                  "Email",
                  "Source",
                  "Assign Time",
                  "Owner",
                  "Follow Up Date Time",
                  "Follow Up Type",
                  "Property Type",
                  "Property Stage",
                  "Project",
                  "Location"
                ],
                "Team Lead": [
                  "Budget",
                  "Contact No.",
                  "Created At",
                  "Created By",
                  "Customer Name",
                  "Email",
                  "Source",
                  "Assign Time",
                  "Location",
                  "Project",
                  "Property Stage",
                  "Property Type",
                  "Follow Up Date Time",
                  "Owner"
                ]
              },
              projects: []
            });
          dispatcher(
            showSnackbarAction(
              "Organization Created!!",
              "success"
            )
          );
          setLoad(false);
          setBooleanSendEmail(true);
          history.replace("/");
        })
        .catch((error) => {
          console.log("Firstore organization error", error);
          setLoad(false);
        });
    })
    .catch((err) => {
      console.log("role error", err);
      dispatcher(
        showSnackbarAction(
          "Organization Already exists!!",
          "error"
        )
      );
      setLoad(false);
    });
};

export const editOrganization = (
  organization_id: string,
  organization_name: string,
  organization_mobile_code: string,
  mobile_number: string,
  email_id: string,
  address: string,
  country: string,
  country_code: string,
  state: string,
  state_code: string,
  city: string,
  city_code: string,
  pincode: number,
  admin_first_name: string,
  admin_last_name: string,
  admin_contact_code: string,
  admin_contact_number: string,
  admin_email_id: string,
  no_of_employees: number,
  cost_per_license: number,
  auth_id: string,
  history: any,
  dispatcher: any,
  setLoad: (data: boolean) => void,
  tillDateString?: any
) => {

  const validTillTimestamp = tillDateString
    ? Firebase.firestore.Timestamp.fromDate(new Date(tillDateString))
    : null;

  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        organization_name: organization_name,
        organization_mobile_code: organization_mobile_code,
        mobile_number: mobile_number,
        email_id: email_id?.toLowerCase(),
        address: address,
        country: country,
        country_code: country_code,
        state: state,
        state_code: state_code,
        city: city,
        city_code: city_code,
        created_at: Firebase.firestore.Timestamp.now(),
        pincode: pincode,
        admin_first_name: admin_first_name,
        admin_last_name: admin_last_name,
        admin_contact_code: admin_contact_code,
        admin_contact_number: admin_contact_number,
        admin_email_id: admin_email_id?.toLowerCase(),
        no_of_employees: no_of_employees,
        cost_per_license: cost_per_license,
        valid_till: validTillTimestamp,
        teams: [],
        designations: [],
        visibility: "true",
        status: "ACTIVE",
        auth_id: auth_id,
        payment_status: true
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Organization Details Updated!!",
          "success"
        )
      );
      setLoad(false);
      history.replace("/");
    })
    .catch((error) => {
      console.log("Edit Org Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};

export const updateApiTokensEditOrg = (
  organization_id: string,
  admin_email: string,
  admin_first_name: string,
  admin_last_name: string,
  country_code: any,
  admin_contact_code: any,
  admin_contact_number: any
) => {
  const apiTokensCollection = firestore.collection('apiTokens');
  const updateAdminDetails = async () => {
    try {
      const snapshot = await apiTokensCollection
        .where('organization_id', '==', organization_id)
        .where('source', '==', "Facebook")
        .get();
      if (snapshot.empty) {
        console.log('No documents found with emailId: demo@gmail.com');
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const updatedData = {
          admin_email: admin_email,
          admin_first_name: admin_first_name,
          admin_last_name: admin_last_name,
          country_code: country_code,
          admin_contact_code: admin_contact_code,
          admin_contact_number: admin_contact_number
        };
        batch.update(doc.ref, updatedData);
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetails();
};

export const updateUsersEditOrg = (
  organization_id: string,
  admin_email: string,
  admin_first_name: string,
  admin_last_name: string,
  admin_contact_code: any,
  admin_contact_number: any,
  no_of_employees: any,
  costPerLicense: any,
  country: any,
  country_code: any,
  state: any
) => {
  const usersDataCollection = firestore.collection('users');
  const updateAdminDetails = async () => {
    try {
      const snapshot = await usersDataCollection
        .where('organization_id', '==', organization_id)
        .where('profile', '==', "Admin")
        .get();
      if (snapshot.empty) {
        console.log('No documents found');
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const updatedData = {
          user_first_name: admin_first_name,
          user_last_name: admin_last_name,
          user_email: admin_email,
          contact_no: admin_contact_number,
          admin_contact_code: admin_contact_code,
          created_at: Firebase.firestore.Timestamp.now(),
          country: country,
          country_code: country_code,
          state: state,
          no_of_employees: no_of_employees,
          cost_per_license: costPerLicense,
        };
        batch.update(doc.ref, updatedData);
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetails();
};

export const updateUsersAdminEmailEditOrg = (
  admin_mail_old: string,
  organization_id: string,
  admin_email: string,
) => {
  console.log("organization_id", organization_id);
  const usersDataCollection = firestore.collection('users');
  const updateAdminDetails = async () => {
    try {
      const snapshot = await usersDataCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        // Check if the current document has the old admin email
        if (data.reporting_to === admin_mail_old) {
          // Update the admin_email with the new value
          const updatedData = {
            reporting_to: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
        // if (data?.contact_owner_email === admin_mail_old) {
        //   const updatedData = {
        //     contact_owner_email: admin_email,
        //   };
        //   batch.update(doc.ref, updatedData);
        // }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetails();
};

export const updateContactEditOrg = (
  admin_mail_old: string,
  organization_id: string,
  admin_email: string,
) => {
  console.log("organization_id", organization_id);
  const contactsCollection = firestore.collection('contacts');
  const updateAdminDetails = async () => {
    try {
      const snapshot = await contactsCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        // Check if the current document has the old admin email
        if (data.reporting_to === admin_mail_old) {
          // Update the admin_email with the new value
          const updatedData = {
            reporting_to: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
        if (data.contact_owner_email === admin_mail_old) {
          const updatedData = {
            contact_owner_email: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetails();
};

export const updateContactEditUsers = (
  user_email_old: string,
  organization_id: string,
  user_email: string,
) => {
  console.log("organization_id", organization_id);
  const contactsCollection = firestore.collection('contacts');
  const updateAdminDetails = async () => {
    try {
      const snapshot = await contactsCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        // Check if the current document has the old admin email
        if (data?.reporting_to === user_email_old) {
          // Update the admin_email with the new value
          const updatedData = {
            reporting_to: user_email,
          };
          batch.update(doc.ref, updatedData);
        }
        if (data.contact_owner_email === user_email_old) {
          const updatedData = {
            contact_owner_email: user_email,
          };
          batch.update(doc.ref, updatedData);
        }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetails();
};

export const updateApiDataEditOrg = (
  admin_mail_old: string,
  organization_id: string,
  admin_email: string,
) => {
  console.log("organization_id", organization_id);
  const apiDataCollection = firestore.collection('API-Data');
  const updateAdminDetailsApiData = async () => {
    try {
      const snapshot = await apiDataCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        // Check if the current document has the old admin email
        // if (data.reporting_to === admin_mail_old) {
        //   // Update the admin_email with the new value
        //   const updatedData = {
        //     reporting_to: admin_email,
        //   };
        //   batch.update(doc.ref, updatedData);
        // }
        if (data.contact_owner_email === admin_mail_old) {
          const updatedData = {
            contact_owner_email: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetailsApiData();
};

export const updateApiDataEditUsers = (
  admin_mail_old: string,
  organization_id: string,
  admin_email: string,
) => {
  const apiDataCollection = firestore.collection('API-Data');
  const updateAdminDetailsApiDataUsers = async () => {
    try {
      const snapshot = await apiDataCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.contact_owner_email === admin_mail_old) {
          const updatedData = {
            contact_owner_email: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetailsApiDataUsers();
};

export const updateTasksEditOrg = (
  admin_mail_old: string,
  organization_id: string,
  admin_email: string,
) => {

  const tasksDataCollection = firestore.collection('tasks');
  const updateAdminDetailsTasks = async () => {
    try {
      const snapshot = await tasksDataCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.contact_owner_email === admin_mail_old) {
          const updatedData = {
            contact_owner_email: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetailsTasks();
};

export const updateTasksEditUsers = (
  admin_mail_old: string,
  organization_id: string,
  admin_email: string,
) => {

  const tasksDataCollection = firestore.collection('tasks');
  const updateAdminDetailsTasksUsers = async () => {
    try {
      const snapshot = await tasksDataCollection
        .where('organization_id', '==', organization_id)
        .get();

      if (snapshot.empty) {
        console.log('No documents found with organization_id:', organization_id);
        return;
      }
      const batch = firestore.batch();
      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.contact_owner_email === admin_mail_old) {
          const updatedData = {
            contact_owner_email: admin_email,
          };
          batch.update(doc.ref, updatedData);
        }
      });
      await batch.commit();
      console.log('Documents updated successfully.');
    } catch (error) {
      console.error('Error updating documents:', error);
    }
  };
  updateAdminDetailsTasksUsers();
};

export const deleteOrgUser = async (
  uid: string,
  setLoad?: (data: boolean) => void,
  dispatcher?: any
) => {
  try {
    const response = await functions.httpsCallable("deleteUser")({
      uid: uid // Pass the UID of the user you want to delete
    });
     await firestore
      .collection('users')
      .doc(uid)
      .delete();

    const orgData = await firestore.collection('organizations').where('auth_id', '==', uid).get();
    if (orgData.empty) {
      console.log('No matching documents.');
      return;
    }
    
    orgData.forEach(doc => {
      doc.ref.delete();
      console.log('Document successfully deleted!');
    });

    dispatcher(
      showSnackbarAction(
        "Organization User deleted successfully!!",
        "success"
      )
    );
    setLoad && setLoad(false);
  } catch (error) {
    console.error('Error deleting user: ', error);
    dispatcher(
      showSnackbarAction(
        "Error deleting organization user. Please try again later.",
        "error"
      ))
    setLoad && setLoad(false);
  }
};

// export const updateApiTokens = (
//   organization_id: string,
//   old_email: string,
//   admin_email_id: string,
// ) => {
//   firestore
//     .collection("apiTokens")
//     .doc(organization_id)
//     .set(
//       {
//         organization_name: organization_name,
//         organization_mobile_code: organization_mobile_code,
//         mobile_number: mobile_number,
//         email_id: email_id?.toLowerCase(),
//         address: address,
//         country: country,
//         country_code: country_code,
//         state: state,
//         state_code: state_code,
//         city: city,
//         city_code: city_code,
//         created_at: Firebase.firestore.Timestamp.now(),
//         pincode: pincode,
//         admin_first_name: admin_first_name,
//         admin_last_name: admin_last_name,
//         admin_contact_code: admin_contact_code,
//         admin_contact_number: admin_contact_number,
//         admin_email_id: admin_email_id?.toLowerCase(),
//         no_of_employees: no_of_employees,
//         cost_per_license: cost_per_license,
//         teams: [],
//         designations: [],
//         visibility: "true",
//         status: "ACTIVE",
//         auth_id: auth_id,
//       },
//       { merge: true }
//     )
//     .then(() => {
//       dispatcher(
//         showSnackbarAction(
//           "Organization Details Updated!!",
//           "success"
//         )
//       );
//       setLoad(false);
//       history.replace("/");
//     })
//     .catch((error) => {
//       console.log("Edit Org Error:", error);
//       dispatcher(
//         showSnackbarAction("Error!! Try Again!!", "error")
//       );
//       setLoad(false);
//     });
// };

export const fetchOrganizationsData = (
  email: string,
  setTeams: (data: any[]) => void,
  setDesignations: (data: any[]) => void,
  setId: (data: string) => void
) => {
  const subscriber = firestore
    .collection("organizations")
    .where("admin_email_id", "==", email)
    .onSnapshot((organization) => {
      if (organization) {
        const org = organization.docs[0];
        organizationId = org.id;
        setId(organizationId);
        setTeams(organization.docs[0].data().teams);
        setDesignations(
          organization.docs[0].data().designations
        );
      } else {
        console.log("org not found");
      }
    });
  return subscriber;
};

export const updatePrimaryLeader = (
  organization_id: string,
  primary_lead_manager_email: string
) => {
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        primary_lead_manager: primary_lead_manager_email,
      },
      { merge: true }
    )
    .then(() => {
      console.log("Primary lead manager updated");
    })
    .catch((e) => {
      console.log("Error:", e);
    });
};

export const updateTeams = (
  teamName: string,
  setLoad: (value: boolean) => void,
  organization_id: string,
  setShowTeamModal: (value: boolean) => void,
  dispatcher: any
) => {
  setLoad(true);
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        teams:
          Firebase.firestore.FieldValue.arrayUnion(
            teamName
          ),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("Team Added", "success")
      );
      setLoad(false);
      setShowTeamModal(false);
    })
    .catch((e) => {
      console.log("Error", e);
    });
};

export const updateDesignations = (
  designation: string,
  setLoad: (value: boolean) => void,
  organization_id: string,
  setShowDesigModal: (value: boolean) => void,
  dispatcher: any
) => {
  setLoad(true);
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        designations:
          Firebase.firestore.FieldValue.arrayUnion(
            designation
          ),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("Designation Added", "success")
      );
      setLoad(false);
      setShowDesigModal(false);
    })
    .catch((e) => {
      console.log("Error", e);
    });
};

export const updateOrganizationStatus = (
  organization_id: string,
  status: string,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      {
        status: status,
      },
      { merge: true }
    )
    .then(() => {
      console.log("Status updated");
      setLoad(false);
    })
    .catch((e) => {
      console.log("Error:", e);
      setLoad(false);
    });
};

export const updateOrganizationData = (
  organization_id: string,
  data: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      data,
      { merge: true }
    )
    .then(() => {
      console.log("Status updated");
      setLoad(false);
    })
    .catch((e) => {
      console.log("Error:", e);
      setLoad(false);
    });
};

export const updateOrganizationAnalyticsData = (
  organization_id: string,
  data: any,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection("organizations")
    .doc(organization_id)
    .set(
      data,
      { merge: true }
    )
    .then(() => {
      console.log("Statusvbvb updated");
      setLoad(false);
    })
    .catch((e) => {
      console.log("Error:", e);
      setLoad(false);
    });
};


export const updateAPIStatus = (
  id: string,
  status: string,
  setLoad: (data: boolean) => void
) => {
  firestore
    .collection("apiTokens")
    .doc(id)
    .set(
      {
        status: status,
      },
      { merge: true }
    )
    .then(() => {
      console.log("Status updated");
      setLoad(false);
    })
    .catch((e) => {
      console.log("Error:", e);
      setLoad(false);
    });
};

export const fetchUsers = (
  setData: (data: any[]) => void,
  dispatcher: any
) => {
  const subscriber = firestore
    .collection("users")
    .onSnapshot((users) => {
      const data: any[] = [];

      if (users) {
        users.forEach((users) => {
          data.push(users.data());
        });

        setData(data);
      } else {
        setData([]);
      }
    });
  return subscriber;
};

// create user and send them generated password to login
export const createUser = async (
  user_email: string,
  user_first_name: string,
  user_last_name: string,
  team: any,
  reporting_to: any,
  contact_no: string,
  admin_contact_code: number,
  designation: any,
  branch: any,
  status: string,
  profile: string,
  dispatcher: any,
  history: any,
  id: any,
  created_by: string,
  country: string,
  state: string,
  multiple: boolean,
  setLoad?: (data: boolean) => void,
  reportingToProfile?: any,
  setBooleanSendEmail?: (data: boolean) => void,
  setPwd?: (data: any) => void,
) => {
  let check = false;
  if (reportingToProfile) {
    check = true;
    let reportingProfile = "";
    reportingToProfile.forEach((item: any) => {
      if (item.value === reporting_to) {
        reportingProfile = item.label;
      }
    });
    if (profile === "CEO") {
      if (
        reportingProfile === "Sales" ||
        reportingProfile === "Team Lead"
      ) {
        dispatcher(
          showSnackbarAction(
            "CEO Cannot Report To Lower Profiles!!",
            "warning"
          )
        );
        setLoad && setLoad(false);
      } else {
        check = false;
      }
    } else if (profile === "Lead Manager") {
      if (
        reportingProfile === "Sales" ||
        reportingProfile === "Team Lead" ||
        reportingProfile === "CEO"
      ) {
        dispatcher(
          showSnackbarAction(
            "Lead Manager Cannot Report To Lower Profiles!!",
            "warning"
          )
        );
        setLoad && setLoad(false);
      } else {
        check = false;
      }
    } else if (profile === "Team Lead") {
      if (reportingProfile === "Sales") {
        dispatcher(
          showSnackbarAction(
            "Team Lead Cannot Report To Lower Profiles",
            "warning"
          )
        );
        setLoad && setLoad(false);
      } else {
        check = false;
      }
    } else {
      check = false;
    }
  }
  if (check === false) {
    let result: { data: any } = { data: true };
    if (multiple === false) {
      const funcData = JSON.stringify({
        contact_no: contact_no,
      });
      result = await functions.httpsCallable("checkUser")(
        funcData
      );
    }
    if (result.data === false) {
      dispatcher(
        showSnackbarAction(
          "User Contact Number Already Exists!!",
          "warning"
        )
      );
      setLoad && setLoad(false);
    } else {
      try {
        let password = generatePassword(9);
        setPwd && setPwd(password);
        const response = await functions.httpsCallable(
          "createUser"
        )(
          JSON.stringify({
            role: profile,
            organization_id: id,
            userData: {
              email: user_email,
              password: password,
              displayName:
                user_first_name + " " + user_last_name,
            },
          })
        );
        try {
          await firestore
            .collection("users")
            .doc(response.data)
            .set(
              {
                user_first_name,
                user_last_name: user_last_name
                  ? user_last_name
                  : "",
                user_email: user_email.toLowerCase(),
                team,
                admin_contact_code,
                reporting_to,
                contact_no,
                designation,
                status,
                organization_id: id,
                created_by: created_by,
                created_at:
                  Firebase.firestore.Timestamp.now(),
                profile,
                uid: response.data,
                user_image: "",
                device_id: "",
                country: country,
                state: state,
                branch: branch,
              },
              { merge: true }
            );
          dispatcher(
            showSnackbarAction("User Created!!", "success")
          );
          setBooleanSendEmail && setBooleanSendEmail(true);
          setLoad && setLoad(false);
          // history.replace("/");
        } catch (error) {
          console.log("Firstore organization error", error);
          setLoad && setLoad(false);
        }
      } catch (error) {
        dispatcher(
          showSnackbarAction(
            "User Email ID Already exists!!",
            "error"
          )
        );
        setLoad && setLoad(false);
      }
    }
  }
};

export const setLeadPermission = (
  organization_id: string,
  profile: string,
  options: any[],
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  organizationUsers: any[]
) => {
  firestore
    .collection("organizationResources")
    .doc(organization_id)
    .set(
      {
        permission: {
          [profile]: options,
        },
      },
      { merge: true }
    )
    .then(() => {
      organizationUsers.forEach((user) => {
        if (user.profile === profile) {
          if (user.leadView) {
            Object.keys(user.leadView).forEach((key) => {
              let data: string[] = [];
              user.leadView[key].forEach((item: string) => {
                if (options.includes(item)) {
                  data.push(item);
                }
              });
              user.leadView[key] = data;
            });
            firestore.collection("users").doc(user.uid).set(
              {
                leadView: user.leadView,
              },
              { merge: true }
            );
          }
        }
      });

      dispatcher(
        showSnackbarAction(
          "Lead View Permission Updated",
          "success"
        )
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("Lead View Error", error);
      setLoad(false);
      close();
    });
};

export const fetchOrganizationLeadPermission = (
  organization_id: string,
  setPermissions: (data: any) => void
) => {
  const subscriber = firestore
    .collection("organizationResources")
    .doc(organization_id)
    .onSnapshot((resources) => {
      if (resources) {
        const resourceData = resources.data();
        if (resourceData?.permission) {
          setPermissions(resourceData?.permission);
        } else {
          setPermissions([]);
        }
      } else {
        console.log("Org not found");
      }
    });
  return subscriber;
};

export const autoFacebookCreateApi = (
  organization_id: any,
  source: string,
  countryCode: any,
  admin_email: string,
  admin_contact_code: number,
  admin_contact_number: string,
  admin_first_name: string,
  admin_last_name: string,

) => {
  firestore
    .collection("apiTokens")
    .doc()
    .set({
      created_at: Firebase.firestore.Timestamp.now(),
      organization_id: organization_id,
      source: source,
      country_code: countryCode,
      status: "ACTIVE",
      admin_email: admin_email,
      admin_contact_code: admin_contact_code,
      admin_contact_number: admin_contact_number,
      admin_last_name: admin_last_name,
      admin_first_name: admin_first_name
    })
    .then((res) => {
      console.log("Response for auto create facebook:", res);
    })
    .catch((error) => {
      console.log("API Error for auto create facebook:", error);
    });
};

export const autoCreateApi = (
  organization_id: any,
  source: string,
  countryCode: string,
) => {
  firestore
    .collection("apiTokens")
    .doc()
    .set({
      created_at: Firebase.firestore.Timestamp.now(),
      organization_id: organization_id,
      source: source,
      country_code: countryCode,
      status: "ACTIVE",
    })
    .then((res) => {
      console.log("Response for auto create Api token:", res);
    })
    .catch((error) => {
      console.log("API Error for auto create api token:", error);
    });
};

export const createApi = (
  organization_id: string,
  source: string,
  countryCode: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void
) => {
  // Fetch admin_email from organizations collection
  firestore
    .collection("organizations")
    .doc(organization_id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const admin_email = doc.data()?.admin_email_id;
        const admin_contact_number = doc.data()?.admin_contact_number;
        const admin_contact_code = doc.data()?.admin_contact_code;
        const admin_first_name = doc.data()?.admin_first_name;
        const admin_last_name = doc.data()?.admin_last_name;

        let apiTokenData = {}
        // Now, you can use admin_email to set up your API
        // Check if the source is "Facebook" and set an additional admin_email
        if (source.toLowerCase() === "facebook") {
          apiTokenData = {
            created_at: Firebase.firestore.Timestamp.now(),
            organization_id: organization_id,
            source: source,
            country_code: countryCode,
            status: "ACTIVE",
            admin_email: admin_email,
            admin_contact_code: admin_contact_code,
            admin_contact_number: admin_contact_number,
            admin_last_name: admin_last_name,
            admin_first_name: admin_first_name
          };
        }
        else {
          apiTokenData = {
            created_at: Firebase.firestore.Timestamp.now(),
            organization_id: organization_id,
            source: source,
            country_code: countryCode,
            status: "ACTIVE",
          };
        }
        firestore
          .collection("apiTokens")
          .doc()
          .set(apiTokenData)
          .then(() => {
            dispatcher(
              showSnackbarAction("API Created !!", "success")
            );
            setLoad(false);
            close();
          })
          .catch((error) => {
            console.log("API Error:", error);
            dispatcher(
              showSnackbarAction("Error!! Try Again!!", "error")
            );
            setLoad(false);
          });
      } else {
        // Handle case where organization document doesn't exist
        console.log("Organization not found");
        dispatcher(
          showSnackbarAction("Organization not found", "error")
        );
        setLoad(false);
      }
    })
    .catch((error) => {
      console.log("Fetch Organization Error:", error);
      dispatcher(
        showSnackbarAction("Error fetching organization data", "error")
      );
      setLoad(false);
    });
};

// export const createApi = (
//   organization_id: string,
//   source: string,
//   countryCode: string,
//   dispatcher: any,
//   setLoad: (val: boolean) => void,
//   close: () => void
// ) => {
//   firestore
//     .collection("apiTokens")
//     .doc()
//     .set({
//       created_at: Firebase.firestore.Timestamp.now(),
//       organization_id: organization_id,
//       source: source,
//       country_code: countryCode,
//       status: "ACTIVE",
//     })
//     .then(() => {
//       dispatcher(
//         showSnackbarAction("API Created !!", "success")
//       );
//       setLoad(false);
//       close();
//     })
//     .catch((error) => {
//       console.log("API Error:", error);
//       dispatcher(
//         showSnackbarAction("Error!! Try Again!!", "error")
//       );
//       setLoad(false);
//     });
// };

export const updateApiTokenFBAccesToken = (
  organization_id: string,
  longToken?: any,
  dispatcher?: any
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref
          .set(
            {
              access_token: longToken
            },
            { merge: true }
          )
          .then(() => {
            // dispatcher(
            //   showSnackbarAction("Facebook successfully!!", "success")
            // );
            console.log("Facebook successfully!");
          })
          .catch((error) => {
            console.log("Api edit error:", error);
            // dispatcher(
            //   showSnackbarAction("Try Again!!", "error")
            // );
          });
      });
    })
    .catch((error) => {
      console.log("Error querying apiTokens:", error);
    });
};
export const updateApiFBPageIdUnScr = (
  organization_id: string,
  page_id?: any,
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const source = (doc.data().source || "").toLowerCase();
        if (source === "facebook") {
          const existingPageIds = doc.data().page_id || [];
          const updatedPageIds = existingPageIds.filter((id: any) => id !== page_id); // Filter out the specified page_id from the array
          doc.ref
            .set(
              {
                page_id: updatedPageIds,
              },
              { merge: true }
            )
            .then(() => {
              console.log("Api token updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating apiTokens:", error);
            });
        }
      });
    })
    .catch((error) => {
      console.error("Error querying apiTokens:", error);
    });
}

export const updateApiFBPageId = (
  organization_id: string,
  page_id?: any,
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const source = (doc.data().source || "").toLowerCase();
        if (source === "facebook") {
          // let updatePageIdArr: any[] = []; // Explicitly type updatePageIdArr as an array
          // updatePageIdArr.push(page_id); // Push page_id to the array
          doc.ref
            .update({ page_id }) // Update with the updatedPageIds array
            .then(() => {
              console.log("Api token updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating apiTokens:", error);
            });
        }
      });
    })
    .catch((error) => {
      console.error("Error querying apiTokens:", error);
    });
};

// export const updateApiFBPageId = (
//   organization_id: string,
//   page_id?: any,
// ) => {
//   firestore
//     .collection("apiTokens")
//     .where("organization_id", "==", organization_id)
//     .get()
//     .then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         const existingPageIds = doc.data().page_id || []; // Existing page_id array or an empty array if not present
//         const updatedPageIds = [...existingPageIds, page_id]; // Adding the new page_id to the array
//         doc.ref
//           .set(
//             {
//               page_id: updatedPageIds,
//             },
//             { merge: true }
//           )
//           .then(() => {
//             console.log("Api token updated successfully!");
//           })
//           .catch((error) => {
//             console.error("Error updating apiTokens:", error);
//           });
//       });
//     })
//     .catch((error) => {
//       console.error("Error querying apiTokens:", error);
//     });
// };

export const updateApiTokenFB = (
  organization_id: string,
  longToken?: any,
  dispatcher?: any,
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const source = (doc.data().source || "").toLowerCase(); // Get lowercase value of source
        if (source === "facebook") {
          const storedValueTokenValue = sessionStorage.getItem("fbssls_296542553118517");
          console.log("firbase function", JSON.stringify(storedValueTokenValue));
          doc.ref
            .set(
              {
                //subscription:subscription,
                // app_id: "243110011829925",
                // app_secret: "e2e2ef4ec20e1f41557b54b877227b3f",
                app_id: "296542553118517",
                app_secret: "143f8ed7ddec986f25598654d8b686f6",
                "fbssls_296542553118517": storedValueTokenValue,
                access_token: longToken,
              },
              { merge: true }
            )
            .then(() => {
              console.log("Api token updated successfully!");
            })
            .catch((error) => {
              console.error("Error updating apiTokens:", error);
            });
        }
      });
    })
    .catch((error) => {
      console.error("Error querying apiTokens:", error);
    });
};



export const getFbsslsValueFromApiTokenFB = (
  organization_id: string,
  keyToRetrieve: string = "fbssls_296542553118517",
  dispatcher?: any,
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const source = (doc.data().source || "").toLowerCase(); // Get lowercase value of source
        if (source === "facebook") {
          const existingData = doc.data();
          const fbsslsValue = existingData[keyToRetrieve];
          // Check if fbsslsValue is not undefined or null
          if (fbsslsValue !== undefined && fbsslsValue !== null) {
            // Use or dispatch the retrieved value as needed
            console.log("Retrieved value:", fbsslsValue);
            // Set the retrieved value in session storage
            sessionStorage.setItem(keyToRetrieve, fbsslsValue);
            // If you need to dispatch the retrieved value, you can use the dispatcher
            // Example: dispatcher(fbsslsValue);
          } else {
            console.warn("fbsslsValue is undefined or null. Not setting in session storage.");
          }
        }
      });
    })
    .catch((error) => {
      console.error("Error querying apiTokens:", error);
    });
};

export const deleteKeyFromApiTokenFB = async (
  organization_id: string,
  keyToDelete: string = "fbssls_296542553118517",
) => {
  const querySnapshot = await firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get();

  const updatePromises = querySnapshot.docs.map(async (doc) => {
    console.log("existingDataDoc", doc.data());
    const source = (doc.data().source || "").toLowerCase();
    if (source === "facebook") {
      const existingData = { ...doc.data() }; // create a shallow copy
      console.log("existingDataDocId", doc.id);

      // Remove the keyToDelete from existingData
      delete existingData[keyToDelete];

      const upDocRef = firestore.collection('apiTokens').doc(doc.id);

      try {
        console.log("existingData", existingData);
        await upDocRef.set(existingData); // set the updated data
        console.log("Document updated successfully!");
      } catch (error) {
        console.error("Error updating document:", error);
      }
    }
  });

  try {
    await Promise.all(updatePromises);
  } catch (error) {
    console.error("Error updating documents:", error);
  }
};

// export const updateApiTokenFB = (
//   organization_id: string,
//   longToken?: any,
//   // subscription?:any,
//   // appIdInput?:any,
//   // appSecretInput?:any,
//   // pageIdInput?:any,
//   // handleClose?: () => void,
//   dispatcher?: any,
//   // longToken?:any
// ) => {
//   firestore
//     .collection("apiTokens")
//     .where("organization_id", "==", organization_id)
//     .get()
//     .then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         doc.ref
//           .set(
//             {
//               //subscription:subscription,
//               app_id: "243110011829925",
//               app_secret: "e2e2ef4ec20e1f41557b54b877227b3f",
//               access_token: longToken,
//             },
//             { merge: true }
//           )
//           .then(() => {
//             // handleClose && handleClose();
//             // dispatcher(
//             //   showSnackbarAction("Subscription successfully!!", "success")
//             // );
//             console.log("Api token updated successfully!");
//           })
//           .catch((error) => {
//             console.log("Api edit error:", error);
//             // handleClose && handleClose();
//             // dispatcher(
//             //   showSnackbarAction("Try Again!!", "error")
//             // );
//           });
//       });
//     })
//     .catch((error) => {
//       console.log("Error querying apiTokens:", error);
//     });
// };

export const editApi = (
  source: string,
  countryCode: string,
  token: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void,
  editClose: () => void
) => {

  firestore
    .collection("apiTokens")
    .doc(token)
    .set(
      {
        source: source,
        country_code: countryCode,
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("API Edited!!", "success")
      );
      setLoad(false);
      close();
      editClose();
    })
    .catch((error) => {
      dispatcher(
        showSnackbarAction("Try Again!!", "error")
      );
      console.log("Api edit error:", error);
      setLoad(false);
    });
};

export const createNews = (
  organization_id: string,
  areaName: string,
  link: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void
) => {
  firestore
    .collection("news")
    .doc(organization_id)
    .set(
      {
        news: Firebase.firestore.FieldValue.arrayUnion({
          created_at: Firebase.firestore.Timestamp.now(),
          name: areaName,
          link: link,
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("News Link Added !!", "success")
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("News Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};
export const createNewsSuperAdmin = (
  areaName: string,
  link: string,
  dispatcher: any,
  setLoad: (val: boolean) => void,
  close: () => void
) => {
  firestore
    .collection("news")
    .doc(" ")
    .set(
      {
        news: Firebase.firestore.FieldValue.arrayUnion({
          created_at: Firebase.firestore.Timestamp.now(),
          name: areaName,
          link: link,
          createNewsProfile:"Super Admin"
        }),
      },
      { merge: true }
    )
    .then(() => {
      dispatcher(
        showSnackbarAction("News Link Added !!", "success")
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("News Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};

export const deleteNews = (
  organization_id: string,
  filterDataArr: any,
  dispatcher: any,
) => {
  firestore
    .collection('news')
    .doc(organization_id).set(
      { news: filterDataArr },
      { merge: true }
    )
    .then(() => {
      dispatcher(showSnackbarAction('News Deleted', 'success'));
    })
    .catch((error) => {
      dispatcher(showSnackbarAction('Error!! Please Try Again!!', 'error'));
    });
};


export const fetchAllUserList = (
  setAllUserList: (data: any[]) => void,
  dispatcher: any,
) => {
  const subscriber = firestore
    .collection("users")
    .where("profile", "!=", "Admin")
    .onSnapshot(async (userlist) => {
      const data: any[] = [];

      if (userlist) {
        // Use Promise.all to wait for all asynchronous operations to complete
        await Promise.all(userlist.docs.map(async (list) => {
          const userData = { ...list.data() };

          // Fetch organization_name based on organization_id
          if (userData?.organization_id) {
            try {
              const organizationDoc = await firestore
                .collection("organizations")
                .doc(userData?.organization_id)
                .get();
              if (organizationDoc?.exists) {
                userData.organization_name = organizationDoc?.data()?.organization_name;
              } else {
                userData.organization_name = "";
                console.log(`Organization with ID ${userData?.organization_id} not found`);
              }
            } catch (error) {
              console.error("Error fetching organization data:", error);
            }
          }
          data.push(userData);
          console.log("userdata List", userData);
        }));

        console.log("userdata inside", data);
        setAllUserList(data);
      } else {
        console.log("Api error");
      }
    });

  return subscriber;
};



// export const fetchAllUserList = (
//   setAllUserList: (data: any[]) => void,
//   dispatcher: any,
// ) => {
//   const subscriber = firestore
//     .collection("users")
//     .onSnapshot((userlist) => {
//       const data: any[] = [];

//       if (userlist) {
//         userlist.forEach((list) => {
//           data.push({ ...list.data()});
//         });
//         setAllUserList(data);
//       } else {
//         console.log("Api error");
//       }
//     });
//   return subscriber;
// };

export const fetchApiFilter = (
  organization_id: string,
  setAPI: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .onSnapshot((apis) => {
      const data: any[] = [];

      if (apis) {
        apis.forEach((api) => {
          data.push({ ...api.data(), api_key: api.id });
        });
        // const filterApiData = data?.filter((item: any) => item?.source.toLowerCase() != 'facebook')
        setAPI(data);
      } else {
        console.log("Api error");
      }
    });
  return subscriber;
};

export const fetchApi = (
  organization_id: string,
  setAPI: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .onSnapshot((apis) => {
      const data: any[] = [];

      if (apis) {
        apis.forEach((api) => {
          data.push({ ...api.data(), api_key: api.id });
        });
        setAPI(data);
      } else {
        console.log("Api error");
      }
    });
  return subscriber;
};

export const fetchNews = (
  organization_id: string,
  setNews: (data: any[]) => void
) => {
  const getNewsWithOrgId = firestore
    .collection('news')
    .doc(organization_id)
    .onSnapshot(doc => {
      if (doc.exists) {
        updateNews();
      }
    }, error => {
      console.error(`Error fetching document with ID ${organization_id}: `, error);
    });

  const getNewsWithOutOrgId = firestore
    .collection('news')
    .doc(' ')
    .onSnapshot(doc => {
      if (doc.exists) {
        updateNews();
      }
    }, error => {
      console.error(`Error fetching document with ID ' ': `, error);
    });

  const updateNews = async () => {
    try {
      const [specificDoc, generalDoc] = await Promise.all([
        firestore.collection('news').doc(organization_id).get(),
        firestore.collection('news').doc(' ').get()
      ]);
      const specificData = specificDoc.exists ? specificDoc.data() : null;
      const generalData = generalDoc.exists ? generalDoc.data() : null;
      const allNews = [
        ...(specificData?.news ?? []),
        ...(generalData?.news ?? [])
      ];
      const sortedNews = allNews.sort((a: any, b: any) => a.created_at - b.created_at);
      setNews(sortedNews);
    } catch (error) {
      console.error('Error updating News: ', error);
      setNews([]);
    }
  };
  // Cleanup function to unsubscribe from the listeners
  return () => {
    getNewsWithOrgId();
    getNewsWithOutOrgId();
  };
};


export const fetchNewsSuperAdmin = (
  setNews: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("news")
    .doc(" ")
    .onSnapshot((news) => {
      if (news) {
        const newsData = news.data();
        if (newsData?.news) {
          setNews(newsData.news);
        } else {
          setNews([]);
        }
      } else {
        console.log("organization not found");
      }
    });
  return subscriber;
};

export const fetchApiData = (
  organization_id: string,
  setAPIData: (data: any[]) => void
) => {
  const subscriber = firestore
    .collection("API-Data")
    .where("organization_id", "==", organization_id)
    .onSnapshot((apis) => {
      const data: any[] = [];
      if (apis) {
        apis?.docs?.forEach((api) => {
          if (typeof (api?.data().associate_contact) === 'string') {
            let associateContact = api.data().associate_contact === "" || api.data().associate_contact === "NA" || api.data().associate_contact === "$answer_agent_number" ? "NA" : api.data().associate_contact;
            data.push({ ...api.data(), "associate_contact": associateContact });
          }
        });
        setAPIData(data);
      } else {
        console.log("Api Data error");
      }
    });
  return subscriber;
};

export const fetchApiFilterData = (
  organization_id: string,
  setAPIData: (data: any[]) => void,
  prevDate: any,
  currentDate: any
) => {
  const subscriber = firestore
    .collection("API-Data")
    .where("organization_id", "==", organization_id)
    .where("created_at", ">=", prevDate)
    .where("created_at", "<=", currentDate)
    .onSnapshot((apis) => {
      const data: any[] = [];
      if (apis) {
        apis.docs.forEach((api) => {
          if (typeof (api?.data().associate_contact) === 'string') {
            let associateContact = api.data().associate_contact === "" || api.data().associate_contact === "NA" || api.data().associate_contact === "$answer_agent_number" ? "NA" : api.data().associate_contact;
            data.push({ ...api.data(), "associate_contact": associateContact });
          }
        });
        setAPIData(data);
      } else {
        console.log("Api Data error");
      }
    });
  return subscriber;
};

export const correctLeadCount = (
  organization_id: string,
  setLoad: (data: boolean) => void,
  dispatcher: any
) => {
  firestore
    .collection("contacts")
    .where("organization_id", "==", organization_id)
    .get()
    .then((leads) => {
      const finalData: {
        [key: string]: {
          [key: string]: { [key: string]: number };
        };
      } = {};

      leads.forEach((lead) => {
        const leadData = lead.data();
        const uid =
          leadData.uid === ""
            ? "NOTASSIGNED"
            : leadData.uid;
        if (
          leadData.organization_id === "" ||
          leadData.stage === ""
        ) {
          return;
        }
        if (finalData[leadData.organization_id]) {
          if (finalData[leadData.organization_id][uid]) {
            if (
              finalData[leadData.organization_id][uid][
              leadData.stage
              ]
            ) {
              finalData[leadData.organization_id][uid][
                leadData.stage
              ] += 1;
            } else {
              finalData[leadData.organization_id][uid][
                leadData.stage
              ] = 1;
            }
          } else {
            finalData[leadData.organization_id][uid] = {
              [leadData.stage]: 1,
            };
          }
        } else {
          finalData[leadData.organization_id] = {
            [uid]: { [leadData.stage]: 1 },
          };
        }
      });

      Object.keys(finalData).forEach((orgId) => {
        Object.keys(finalData[orgId]).forEach((user) => {
          if (finalData[orgId][user]["INTERESTED"]) {
            finalData[orgId][user]["FOLLOWUP"] =
              finalData[orgId][user]["INTERESTED"];
          } else {
            finalData[orgId][user]["FOLLOWUP"] = 0;
          }
          if (finalData[orgId][user]["CALLBACK"]) {
            finalData[orgId][user]["FOLLOWUP"] +=
              finalData[orgId][user]["CALLBACK"];
          }
        });
      });

      firestore
        .collection("counts")
        .doc(organization_id)
        .set(finalData[organization_id])
        .then(() => {
          setLoad(false);

          dispatcher(
            showSnackbarAction(
              "Counts Corrected!!",
              "success"
            )
          );
        });
    });
};

export const updateApiPrimaryLead = (
  organization_id: string,
  uid: string,
  primary_lead_manager_email: string
) => {
  firestore
    .collection("apiTokens")
    .where("organization_id", "==", organization_id)
    .get()
    .then((docs) => {
      const batch = firestore.batch();
      docs.forEach((doc) => {
        batch.set(
          doc.ref,
          {
            primary_lead_manager_email:
              primary_lead_manager_email,
            primary_lead_manager_uid: uid,
          },
          { merge: true }
        );
      });

      batch
        .commit()
        .then(() => {
          console.log("updateApiPrimaryLead", "success");
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    });
};

export const changeLeadsStage = (
  leadsList: any[],
  dispatcher: any,
  setLoad: (data: boolean) => void
) => {
  leadsList.forEach((item) => {
    firestore
      .collection("contacts")
      .doc(item)
      .set(
        {
          stage: "FRESH",
          next_follow_up_type: "",
          next_follow_up_date_time: "",
          not_int_reason: "",
          lost_reason: "",
          other_not_int_reason: "",
          other_lost_reason: "",
        },
        { merge: true }
      )
      .then(() => {
        dispatcher(
          showSnackbarAction("Lead Stage Update", "success")
        );
        setLoad(false);
      })
      .catch((err) => {
        console.log("Lead Stage Error:", err);
        dispatcher(
          showSnackbarAction("Please Try Again!!", "error")
        );
        setLoad(false);
      });
  });
};

export const createCustomButton = (
  organization_id: string,
  name: string,
  url: string,
  parameters: object | undefined,
  headers: object | undefined,
  type: string,
  setLoad: (data: boolean) => void,
  dispatcher: any,
  close: () => void
) => {
  firestore
    .collection("customButtons")
    .doc()
    .set({
      created_at: Firebase.firestore.Timestamp.now(),
      organization_id: organization_id,
      name: name,
      url: url,
      parameters: parameters ? parameters : {},
      headers: headers ? headers : {},
      type: type,
    })
    .then(() => {
      dispatcher(
        showSnackbarAction(
          "Custom Button Created !!",
          "success"
        )
      );
      setLoad(false);
      close();
    })
    .catch((error) => {
      console.log("Custom Button Error:", error);
      dispatcher(
        showSnackbarAction("Error!! Try Again!!", "error")
      );
      setLoad(false);
    });
};


export const fetchCustomButtons = (
  organization_id: string,
  setCustomButton: (data: any[]) => void
) => {
  sessionStorage.getItem('org')
  type props = {
    parameters: any;
    type: string;
    headers: any;
    created_at: any;
    name: string;
    url: string;
    organization_id: string;
  };


  const subscriber = firestore
    .collection("customButtons")
    .where("organization_id", "==", sessionStorage.getItem('org'))
    .onSnapshot((customButton) => {
      if (customButton) {
        const data: any[] = [];
        customButton?.forEach((api) => {
          data?.push(api?.data());
        });
        setCustomButton(data);
        console.log('data', data)
      } else {
        console.log("custom button error");
      }
    });
  return subscriber;
};

export const fetchOrganizationsUserDropDown = (
  organization_id?: string,
  setFinalDataList?: (users: any[]) => void,
) => {
  const subscribe = firestore
      .collection('users')
      .where('organization_id', '==', organization_id)
      .onSnapshot((snapshot) => {
          const data: any[] = [];
          snapshot.forEach((doc) => {
              const userData = doc.data();
              // Extract uid and user_email from userData
              const { uid, user_email } = userData;
              data.push({ uid, user_email });
          });
          if (setFinalDataList) {
              setFinalDataList(data.sort((a, b) => a.user_email.localeCompare(b.user_email)));
          } else {
              console.log('setFinalDataList is not provided');
          }
      });
  return subscribe;
};