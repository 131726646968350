import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// import { FacebookProvider, LoginButton } from 'react-facebook';
import styles from "./Integration.module.css";
import { themeColors } from "../../Components/theme";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedIn from '@mui/icons-material/LinkedIn';
import FacebookLogin from "react-facebook-login";
import { FacebookProvider, LoginButton, Like } from 'react-facebook';
// import LinkedInPage from "../IntegrationLinkedin/IntegrationLinkedin";
import axios from "axios";
import { deleteKeyFromApiTokenFB, fetchApi, getFbsslsValueFromApiTokenFB, updateApiFBPageId, updateApiFBPageIdUnScr, updateApiTokenFB } from "../../Services/organizations";
import FormDialogFB from "./DialogFB";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  showSnackbarAction,
  facebookUesrPageData
} from "../../Redux/actions";
import { Link } from 'react-router-dom';
import { Circles, Audio, BallTriangle } from 'react-loader-spinner';
import iconFacebook from '../../Assets/Images/fbicon.png'
import acresImages from  '../../Assets/Images/99acresImages.png'
import magicbricksicon from '../../Assets/Images/magicbricksicon.png'
import housingIcon from '../../Assets/Images/housingIcon.png'
import JDimages from '../../Assets/Images/JDimages.png'
import skh from '../../Assets/Images/skh.png'
import iconWeb from '../../Assets/Images/iconWeb.jpg'
import Loading from "../../Components/Loading/Loading";

type props = {
  user: any;
  contacts: any;
  history: any;
  role: any;
  organizationUsers: any;
  teamLeadUsers: any;
  theme: any;
  facebookUesrPageList: any;
};
const loginButtonStyles = {
  backgroundColor: '#272944',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
};
const IntegrationFacebook: FunctionComponent<props> = ({
  user,
  contacts,
  history,
  role,
  organizationUsers,
  teamLeadUsers,
  theme,
  facebookUesrPageList
}) => {
  const dispatcher = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [API, setAPI] = useState<any[] | undefined>(undefined);
  const [showLoader, setShowLoader] = useState(true);
  const [filterActivePages, setFilterActivePages] = useState<any[] | undefined>(undefined);
  const [filterDeactivePages, setFilterDeactivePages] = useState<any[] | undefined>(undefined);
  const [filterAPI, setFilterAPI] = useState<any[] | undefined>(undefined);
  const [accessUserToken, setAccessUserToken] = React.useState('');
  const [rightOpen, setRightOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [userData, setUserData] = useState<any>();
  const [userPages, setUserPages] = useState<any>();
  const [loader, setLoader] = useState(false);
  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  // const responseFacebook = (response: any) => {
  //   console.log("response", response);
  // };

  // const handleSubscriptionsOpenModel = () => {
  //   setOpen(true);
  // }
  useEffect(() => {
    if (user.organization_id) {
      fetchApi(user.organization_id, (val) => setAPI(val));
    }
  }, [user.organization_id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (API) {
      console.log('userPages', facebookUesrPageList)
      const filteredData = API?.filter((item: any) => item.source.toLowerCase() === 'facebook');
      setFilterAPI(filteredData);
      const pageIdArr: any = filteredData[0]?.page_id;
      console.log("pageIdArr", pageIdArr)
      if (pageIdArr) {
        const filteredDataActiveData = facebookUesrPageList?.filter((item: any) => pageIdArr.includes(item.id));
        const filteredDataDiactiveData = facebookUesrPageList?.filter((item: any) => !pageIdArr.includes(item.id));
        console.log("filteredDataActiveData", filteredDataDiactiveData);
        console.log("filtActiveData", filteredDataActiveData);
        setFilterDeactivePages(filteredDataDiactiveData);
        setFilterActivePages(filteredDataActiveData);
      }
      else {
        console.log("DeactivePages", facebookUesrPageList);
        setFilterDeactivePages(facebookUesrPageList);
      }
    }
  }, [API, facebookUesrPageList]);


  // console.log("activeArr",activeArr);
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };

  const buttonStyle = {
    /* Add your custom styles here */
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  //////////////

  // useEffect(() => {
  //   loginStatusCheck();
  //   // getFbsslsValueFromApiTokenFB(user.organization_id);
  // }, [])

  // const handleFacebookLogoutkdk=()=>{
  //   alert("logout");
  //   deleteKeyFromApiTokenFB("wBHuU3VgFtukUprupIBz");
  // }

  // const loginStatusCheck = () => {
  //   window.FB.getLoginStatus(function (response: any) {
  //     if (response.status === 'connected') {
  //       setLoginStatus(true);
  //       // var uid = response.authResponse.userID;
  //       console.log("check user Login", response);
  //       if (!facebookUesrPageList || facebookUesrPageList.length === 0) {
  //         fetchUserPages(response.authResponse.accessToken);
  //       }
  //       // fetchAdSets(response.authResponse.accessToken);
  //       window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
  //         setUserData(userData);
  //         console.log('User data with profile useEffect:', userData);
  //       });
  //       // var accessToken = response.authResponse.accessToken;
  //     } else if (response.status === 'not_authorized') {

  //     }
  //     else {
  //       // the user isn't logged in to Facebook.
  //     }
  //   });
  // }

  // const handleFacebookLogin = () => {
  //   // Check if FB object is available (Facebook SDK is loaded)
  //   if (window.FB) {
  //     // Set the permissions you need for page access
  //     const permissions = ['public_profile', 'email', 'pages_show_list', 'pages_read_engagement', 'pages_manage_metadata', 'pages_manage_ads', 'leads_retrieval', 'ads_management', 'business_management'];
  //     // Trigger the Facebook login dialog
  //     window.FB.login(
  //       function (response: any) {
  //         if (response.authResponse) {
  //           // User has successfully authenticated
  //           console.log('User logged in:', response.authResponse);
  //           // Fetch the granted permissions using the user's access token
  //           window.FB.api('/me/permissions', { access_token: response.authResponse.accessToken }, function (permsResponse: any) {
  //             const grantedPermissions = permsResponse.data.map((permission: any) => permission.permission);
  //             const missingPermissions = permissions.filter(permission => !grantedPermissions.includes(permission));

  //             if (missingPermissions.length === 0) {
  //               // The user has granted all necessary permissions
  //               console.log("missingPermissionstoken", response?.authResponse);
  //               APICallAccessToken(response?.authResponse);
  //               window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
  //                 console.log('User data with profile:', userData);
  //                 setUserData(userData);
  //               });
  //               setLoginStatus(true);
  //               fetchUserPages(response.authResponse.accessToken);
  //             }
  //             else if (missingPermissions.includes("business_management")) {
  //               APICallAccessToken(response?.authResponse);
  //               window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
  //                 setUserData(userData);
  //               });
  //               setLoginStatus(true);
  //               fetchUserPages(response.authResponse.accessToken);
  //             }
  //             else {
  //               // The user did not grant all necessary permissions
  //               console.log('Missing required permissions:', missingPermissions);
  //             }
  //           });
  //         } else {
  //           // User canceled the login or did not fully authorize
  //           console.log('User cancelled login or did not fully authorize.');
  //         }
  //       },
  //       { scope: permissions.join(',') } // Request the permissions you need
  //     );
  //   } else {
  //     // Handle the case where the FB object is not available (Facebook SDK failed to load)
  //     console.error('Facebook SDK not loaded.');
  //   }
  // };

  // const handleFacebookLogin = () => {
  //   // Check if FB object is available (Facebook SDK is loaded)
  //   if (window.FB) {
  //     // Set the permissions you need for page access
  //     const permissions = ['public_profile', 'email', 'pages_show_list', 'pages_read_engagement'];

  //     // Trigger the Facebook login dialog
  //     window.FB.login(
  //       function (response: any) {
  //         if (response.authResponse) {
  //           // User has successfully authenticated
  //           console.log('User logged in:', response.authResponse);

  //           // Check if the user granted the required permissions
  //           const grantedPermissions = response.authResponse.grantedScopes.split(',');
  //           const missingPermissions = permissions.filter(permission => !grantedPermissions.includes(permission));

  //           if (missingPermissions.length === 0) {
  //             // The user has granted all necessary permissions
  //             APICallAccessToken(response?.authResponse);
  //             window.FB.api('/me', { fields: 'name,picture' }, function (userData: any) {
  //               console.log('User data with profile:', userData);
  //               setUserData(userData);
  //             });
  //             setLoginStatus(true);
  //             fetchUserPages(response.authResponse.accessToken);
  //           } else {
  //             // The user did not grant all necessary permissions
  //             console.log('Missing required permissions:', missingPermissions);
  //           }
  //         } else {
  //           // User canceled the login or did not fully authorize
  //           console.log('User cancelled login or did not fully authorize.');
  //         }
  //       },
  //       { scope: permissions.join(',') } // Request the permissions you need
  //     );
  //   } else {
  //     // Handle the case where the FB object is not available (Facebook SDK failed to load)
  //     console.error('Facebook SDK not loaded.');
  //   }
  // };

  // const handleFacebookLogout = () => {
  //   // Check if FB object is available (Facebook SDK is loaded)
  //   if (window.FB) {
  //     window.FB.logout(function (response: any) {
  //       // User has been logged out successfully
  //       console.log('User logged out:', response);
  //       setLoginStatus(false);
  //       setUserData(null); // Clear user data
  //       clearFacebookSessionCookies();
  //       deleteKeyFromApiTokenFB(user.organization_id);
  //       // window.location.reload();
  //     });
  //   } else {
  //     // Handle the case where the FB object is not available (Facebook SDK failed to load)
  //     console.error('Facebook SDK not loaded.');
  //   }
  // };

  // const clearFacebookSessionCookies = () => {
  //   try {
  //     const expirationDate = new Date(0).toUTCString();
  //     document.cookie = `fr=; expires=${expirationDate}; path=/; domain=.facebook.com`;
  //     document.cookie = `xs=; expires=${expirationDate}; path=/; domain=.facebook.com`;
  //     document.cookie = `fbsr=; expires=${expirationDate}; path=/; domain=.facebook.com`;
  //     // Repeat the same for any other Facebook cookies if needed
  //     console.log("Facebook cookies cleared successfully.");
  //   } catch (error) {
  //     console.error("Error clearing Facebook cookies:", error);
  //   }
  // };

  // const APICallAccessToken = async (response: any) => {
  //   try {
  //     const res = await axios.get(`https://graph.facebook.com/oauth/access_token`, {
  //       params: {
  //         grant_type: "fb_exchange_token",
  //         client_id: "296542553118517",
  //         client_secret: "143f8ed7ddec986f25598654d8b686f6",
  //         fb_exchange_token: response.accessToken
  //       }
  //     });
  //     if (res.status === 200) {
  //       console.log('Facebook API response Token:', res);
  //       const longToken = res?.data?.access_token;
  //       // const storedValueTokenValue = sessionStorage.getItem("fbssls_296542553118517");
  //       // console.log("storedValueTokenValue",JSON.stringify(storedValueTokenValue));
  //       updateApiTokenFB(
  //         user.organization_id,
  //         longToken,
  //         dispatcher
  //       );
  //     }
  //   } catch (error) {
  //     console.error('Error posting to Facebook API:', error);
  //   }
  // };
  // const fetchAdSets = (accessToken:any) => {
  //   window.FB.api(
  //     '/{ad-account-id}/adsets',
  //     'GET',
  //     { access_token: accessToken },
  //     function(response:any) {
  //       if (response.error) {
  //         console.log('Graph returned an error:', response.error);
  //       } else {
  //         // Handle the response data here
  //         console.log('Ad Sets:', response.data);
  //       }
  //     }
  //   );
  // };

  // const fetchUserPages = async (accessToken: any) => {
  //   // Make a call to the Facebook Graph API to get the user's pages
  //   window.FB.api('/me/accounts', { access_token: accessToken, limit: 500 }, async function (
  //     response: any
  //   ) {
  //     if (response.data) {
  //       // Check if the user has any pages
  //       if (response.data.length > 0) {
  //         // User has pages, proceed with further actions
  //         try {
  //           const updatedUserPages = await Promise.all(
  //             response.data.map(async (item: any) => {
  //               const res = await axios.get(
  //                 `https://graph.facebook.com/v17.0/${item?.id}/leadgen_forms`,
  //                 {
  //                   params: {
  //                     access_token: item?.access_token,
  //                   },
  //                 }
  //               );

  //               if (res.status === 200) {
  //                 const countActive = res.data?.data?.length;

  //                 console.log('link page API response Token:', res.data?.data);
  //                 console.log("countActive", countActive);
  //                 console.log("item id", item?.id);

  //                 if (item.id === item?.id) {
  //                   // Add formcount to the object if id matches
  //                   return {
  //                     ...item,
  //                     formcount: countActive,
  //                   };
  //                 } else {
  //                   return item;
  //                 }
  //               }
  //             })
  //           );
  //           console.log('updatedUserPages', updatedUserPages);
  //           setUserPages(updatedUserPages);
  //           dispatcher(facebookUesrPageData(updatedUserPages));
  //         } catch (error) {
  //           console.error('Error posting to Facebook API:', error);
  //         }
  //         handlesubscribed(response.data);
  //         console.log('User has pages:', response.data);
  //       } else {
  //         // User does not have any pages
  //         console.log('User does not have any pages.');
  //       }
  //     } else {
  //       // Error occurred while fetching pages
  //       console.error('Error fetching user pages:', response.error);
  //     }
  //   });
  // };

  // const handlesubscribed = async (pagesdata: any) => {
  //   let updateSubscribed: any[] = [];
  //   try {
  //     for (const list of pagesdata) {
  //       try {
  //         setLoader(true);
  //         const res = await axios.post(`https://graph.facebook.com/${list?.id}/subscribed_apps`, {
  //           subscribed_fields: "leadgen",
  //           access_token: list?.access_token
  //         });
  //         if (res.status === 200) {
  //           const page_id = list?.id;
  //           updateSubscribed.push(page_id);
  //           // dispatcher(
  //           //   showSnackbarAction("Page add successfully!!", "success")
  //           // );
  //           console.log('Facebook API response Token:', res);
  //         }
  //       } catch (error) {
  //         console.error('Error posting to Facebook API:', error);
  //       }
  //       // //add pages 
  //       // dispatcher(
  //       //   showSnackbarAction("Page add successfully!!", "success")
  //       // );
  //     }
  //     setLoader(false);
  //     //add pages 
  //     dispatcher(
  //       showSnackbarAction("Page add successfully!!", "success")
  //     );
  //     // console.log("updateSubscribed", updateSubscribed);
  //     // const storedValueTokenValue = sessionStorage.getItem("fbssls_296542553118517");
  //     // console.log("updateSubscribed storedValueTokenValue",JSON.stringify(storedValueTokenValue));

  //     updateApiFBPageId(
  //       user.organization_id,
  //       updateSubscribed
  //     );
  //   } catch (outerError) {
  //     console.error('Error iterating through userPages:', outerError);
  //   }
  // }


  // const handleUnsubscribed = async (id: any) => {
  //   const selectedObject = userPages?.find((option: any) => option.id === id);
  //   try {
  //     const res = await axios.delete(`https://graph.facebook.com/${selectedObject?.id}/subscribed_apps`, {
  //       params: {
  //         subscribed_fields: "leadgen",
  //         access_token: selectedObject?.access_token
  //       }
  //     });
  //     if (res.status === 200) {
  //       console.log("selectedObject", selectedObject);
  //       const page_id = selectedObject?.id;
  //       updateApiFBPageIdUnScr(
  //         user.organization_id,
  //         page_id
  //       );
  //       dispatcher(
  //         showSnackbarAction("Page unsubscribed successfully!!", "success")
  //       );
  //     }
  //   } catch (error) {
  //     console.error('Error unsubscribing from Facebook API:', error);
  //   }
  // };

  const handleFaceBookConnect = () => {
    history.push("/facebook-leads")
  }
  const handle99AcersConnect = () => {
    history.push("/99acres")
  }
  const handleJustdialConnect = () => {
    history.push("/justdial")
  }
  const handleWebsiteConnect = () => {
    history.push("/website")
  }
  const handleSulekhaConnect = () => {
    history.push("/sulekha")
  }
  const handleHousingConnect = () => {
    history.push("/housing")
  }
  const handleMagicbricksConnect = () => {
    history.push("/magicbricks")
  }
  
  return (
    <>
      <div
        className={styles.parent}
        style={{ ...containerStyles, marginTop: "4%", overflow: "hidden", maxHeight: "500px", overflowX: "hidden", overflowY: "scroll", marginBottom: "20px" }}
      >
        {showLoader && <Loading/>}
        {/* outer block */}
        <div style={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column", margin: "20px 0px 20px 50px" }}>
          <h4>Integrations</h4>
          <div style={{ ...containerStyles, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: "87%", justifyContent: "flex-start", alignItems: "flex-start", height: "auto", paddingBottom: "50px", marginBottom: "50px" }}>
            {/* sub block */}
            <div style={{ width: "auto", display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "column" }}>
              {/* heading block */}
              {/* <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "20%", height: "40px", padding: "10px", fontSize: "16px", marginLeft: "20px" }}>
                <a style={{ cursor: "pointer", textDecoration: "underline" }}>Lead Source</a>
              </div> */}
              {/* Distribution block */}
              {/* <div style={{
                ...containerStyles, width: "92%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start",
                backgroundColor: theme == true ? "rgb(223 211 231)" : "",
                marginLeft: "2%", height: "78px"
              }}>
                <div style={{ width: "10%", height: "100%" }}>
                  logo
                </div>
                <div style={{ width: "70%", height: "50%", padding: "10px" }}>
                  <p style={{ fontSize: "14px", fontWeight: "700", textTransform: "uppercase", letterSpacing: "0.05rem", marginBottom: "2px" }}>Lead Distribution</p>
                  <p style={{ marginBottom: "1px" }}>Automatically share leads with multiple recipients via lead distribution rules. You can forward all leads to all recipients, or distribute leads in a round-robin fashion.</p>
                </div>
                <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <button>Set Up</button>
                </div>
              </div> */}
              {/* Lead content block */}
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "auto",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                    <div> <img src={iconFacebook}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Facebook</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from your Facebook &amp; Lead Ads into
                    your Lead Rubix account.
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
                    {loginStatus === true ? <>
                      <a style={{ fontSize: "14px", cursor: "pointer", color: "#106ebe" }} onClick={handleFaceBookConnect} >Connected</a>
                      <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px", fontWeight: "600" }}
                        onClick={handleFaceBookConnect}>Configure</a>
                      {/* <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} /> */}
                    </> : <>
                      <a style={{ fontSize: "14px", cursor: "pointer" }} onClick={handleFaceBookConnect}>Not Connected</a>
                      <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                        onClick={handleFaceBookConnect}>Connected
                        <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                      </a>
                    </>}
                  </div>
                </div>
                {/* new card2 */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                    <div> <img src={acresImages}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>99acres</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from 99acres in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handle99AcersConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
              </div>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                  }}>
                    <div> <img src={magicbricksicon}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>MagicBricks</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from MagicBricks in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleMagicbricksConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                   <div> <img src={housingIcon}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Housing.com</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from Housing.com in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleHousingConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
              </div>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                  }}>
                    <div> <img src={JDimages}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>JustDial</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from JustDial in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleJustdialConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}>
                    <div> <img src={skh}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Sulekha</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from Sulekha in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleSulekhaConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
                {/* new card2 */}
              </div>
              <div style={{ display: "flex", width: "100%", height: "auto", alignItems: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", marginTop: "20px" }}>
                {/* Lead block */}
                <div
                  style={{
                    ...containerStyles,
                    padding: "10px 20px",
                    width: "45%",
                    marginLeft: "2%",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* facebook icon and heading main block */}
                  <div style={{
                    // ...containerStyles,
                    width: "100%",
                    height: "53px",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",

                  }}>
                    <div> <img src={iconWeb}
                      width={30}
                      className="mx-auto my-6 w-60">
                    </img></div>
                    <h2 style={{ paddingLeft: "8px", fontSize: "22px" }}>Website</h2>
                  </div>
                  <div className="mb-2">
                    Receive new leads from your website’s contact form in your Leads Rubix account
                  </div>
                  <div style={{ display: "flex", justifyContent: 'flex-end', width: "100%" }} >
                    <a style={{ color: "#106ebe", cursor: "pointer", fontSize: "14px" }}
                      onClick={handleWebsiteConnect}
                    >Configure
                      <ArrowForwardIosIcon fontSize="small" style={{ color: "#106ebe" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    role: state.user.role,
    contacts: state.contacts,
    organizationUsers: state.organizationUsers.data,
    teamLeadUsers: state.teamLeadUsers.data,
    theme: state.theme.isLightMode,
    facebookUesrPageList: state.faceBookUserPagesData.data,
  };
};
export default connect(mapStateToProps)(IntegrationFacebook);
