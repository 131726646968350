import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./UpgradeUser.module.css";
import { themeColors } from "../../Components/theme";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useRazorpay from "react-razorpay";
import axios from "axios";
import { firestore, functions } from "../../Firebase";
import { url } from "../../Values/constants";
import SuccessAndFailureModal from "../../Components/SuccessAndFailureModal/SuccessAndFailureModal";
import { sendMail } from "../../Values/utils";
import moment from "moment";
import { getDateIsoString, compareDates, addMonthsWithoutTimestamp, getFormattedDate } from "../../Values/utils";
import { numberWithCommas } from "../../Services/booking";
import { fetchLicensesCost } from "../../Services/resources";

const UpgradeUser = ({ theme, user, organizationData, authToken }: any) => {
  const [units, setUnits] = useState<any>(1);
  const [noEmp, setNoEmp] = useState<any>("");
  const [unitsValidation, setUnitsValidation] = useState<any>(false);
  const [costLicensesData, setCostLicensesData] = useState<any[] | undefined>(undefined);
  // const [daysDifference,setDaysDifference] = useState<any>(1);
  const [openModal, setOpenModal] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  let costPerLicense = organizationData && organizationData.cost_per_license;
  let validTillTimestamp = organizationData && organizationData.valid_till;
  let validTill = getDateIsoString(validTillTimestamp);
  let currentDate = new Date().toISOString().slice(0, 10);
  let isValidTillDatePassed = compareDates(currentDate, validTill);
  let start = moment(currentDate);
  let end = moment(validTill);
  let daysDifference = end.diff(start, "days") + 1;
  let orgName = organizationData && organizationData.organization_name;
  let mailId = organizationData && organizationData.org_mail_id && organizationData.org_mail_id[0];
  let address = organizationData && organizationData.address;
  let contactNumber = organizationData && organizationData.mobile_number;
  let perDayLicenseCost = Math.round(costPerLicense / 30);
  let amount: any = Math.round(perDayLicenseCost * units * daysDifference);
  let tax: any = Math.round(((18 / 100) * amount));
  let totalAmount = Number(amount) + Number(tax);
  let adminMailId = organizationData && organizationData.admin_email_id;
  let isIGST = organizationData && organizationData.is_IGST;

  useEffect(() => {
    fetchLicensesCost((data) => setCostLicensesData(data));
  }, [])

  useEffect(() => {
    const EmployessNo = costLicensesData?.[0]?.no_employees;
    const no_of_employees = organizationData?.org_trial_period_users_licenses
    // const no_of_employees = organizationData.no_of_employees;
    const subNoEmployees = parseInt(EmployessNo) - parseInt(no_of_employees);
    // console.log("organizationData",organizationData?.org_trial_period_users_licenses);
    setNoEmp(subNoEmployees);
  }, [costLicensesData]);

  const updatePlan = (organizationId: any) => {
    // let totalUsers = localStorage.getItem('totalUsers') ? Number(localStorage.getItem('totalUsers')) : units;
    let docRef = firestore.collection("organizations").doc(organizationId);
    docRef.update({
      no_of_employees: Number(organizationData.no_of_employees) + units
    })
      .then(() => {
        setOpenModal(true);
        setIsTransactionSuccess(true);
      })
      .catch((error) => {
        setOpenModal(true);
        setIsTransactionSuccess(false);
      });
  }
  const Razorpay = useRazorpay();

  const handlePayment = useCallback(async (totalAmount: any, totalUsers: any, orgName: any) => {
    const mailUpgradeRequest = `<div>Hello,</div> <br/><div>${orgName} has requested to update their licenses by ${totalUsers}</div> <br/>  <div>Please reach out to them as soon as possible.</div>  <br/>`
    const mailUpgradeRequestFailed = `<div>Hello,</div> <br/><div>${orgName} has requested to update their licenses by ${totalUsers}, but the payment has failed/</div> <br/>  <div>Please reach out to them as soon as possible.</div>  <br/>`
    const mailUpgradeRequestUser = `<div>Hello,</div> <br/><div>Your request to update your Leads Rubix licenses by ${totalUsers} has been forwarded to our team and we will reach out to you as soon as possible.</div> <br/>  <div>Thanks for using Readpro.</div>  <br/>`
    const mailUpgradeRequestUserFailed = `<div>Hello,</div> <br/><div>Your request to update your Leads Rubix licenses by ${totalUsers} could not be fulfilled, we will reach out to you as soon as possible.</div> <br/>  <div>Thanks for using Readpro.</div>  <br/>`
    axios.defaults.headers.common['x-access-token'] =
      authToken
    const res = await axios.post(url + '/payments/createorder', {
      "amount": totalAmount
    });
    let paymentFrom = new Date().toISOString();
    // let paymentTill =  addMonthsWithoutTimestamp(validTill,totalMonths);
    // let formattedPaymentFrom = getFormattedDate(paymentFrom);
    // let formattedPaymentTill = getFormattedDate(paymentTill);
    const { data } = res;
    const options: any = {
      key: "rzp_test_N91p6PGNyIPGjR",
      // key: "rzp_test_PBAMaFcQYsevYS",
      amount: totalAmount,
      currency: "INR",
      name: "Leads Rubix",
      description: "Test Transaction",
      // image: "https://www.itsforyou.live/public/uploads/imp/1624874830.png",
      order_id: data.order.id,
      handler: async (res: any) => {
        axios.defaults.headers.common['x-access-token'] =
          authToken
        const resa = await axios.post(url + '/payments/paymentverification', {
          uid: user.uid,
          contact_no: user.contact_no,
          contact_owner_email: user.user_email,
          organization_id: user.organization_id,
          receipt: data.order.receipt,
          currency: "INR",
          amount: data.order.amount / 100,
          razorpay_order_id: res.razorpay_order_id,
          razorpay_payment_id: res.razorpay_payment_id,
          razorpay_signature: res.razorpay_signature,
          invoice_no: "201906-28",
          company: orgName,
          email: adminMailId,
          phone: contactNumber,
          address: address,
          transaction_date: paymentFrom,
          due_date: paymentFrom,
          paymentFrom: paymentFrom,
          paymentTill: paymentFrom,
          units: units,
          igst: isIGST,
          items: [
            {
              serialNumber: 1,
              description: `${units} license for ${daysDifference} days at Rs ${perDayLicenseCost}/day`,
              qty: 1,
              hsn: 997331,
              rate: totalAmount,
              taxAmount: Number(tax),
            },
          ]
        });
        if (resa.data.success) {
          setOpenModal(true);
          setIsTransactionSuccess(true);
          updatePlan(user.organization_id);
          // sendMail(mailUpgradeRequest,["helpdesk@itsforyou.live","accounts@itsforyou.live"],"Users Upgrade Request");
          // sendMail(mailUpgradeRequestUser,mailId,"Users Upgrade Request");
        } else {
          setOpenModal(true);
          setIsTransactionSuccess(false);
          // sendMail(mailUpgradeRequestUserFailed,mailId,"Users Upgrade Request Failed");
          // sendMail(mailUpgradeRequestFailed,["helpdesk@itsforyou.live","accounts@itsforyou.live"],"Users Upgrade Request");
        }
      },
      prefill: {
        name: orgName,
        email: mailId,
        contact: contactNumber,
      },
      notes: {
        address: address,
      },
      // theme: {
      //   color: "#3399cc",
      // },
    };

    const rzpay = new Razorpay(options);

    rzpay.on("payment.failed", (err: any) => {
      setOpenModal(true);
      setIsTransactionSuccess(false);
      // sendMail(mailUpgradeRequestUserFailed,mailId,"Payment Failed");
      // sendMail(mailUpgradeRequestFailed,["helpdesk@itsforyou.live","accounts@itsforyou.live"],"Payment Failed");
    });

    rzpay.open();
  }, [Razorpay]);

  const history = useHistory();
  const containerStyles = {
    backgroundColor: theme
      ? themeColors.backgroundColor
      : themeColors.backgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const cardContainerStyles = {
    backgroundColor: theme
      ? themeColors.cardBackgroundColor
      : themeColors.cardBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
  };
  const tdStyles = {
    backgroundColor: theme
      ? themeColors.tdBackgroundColor
      : themeColors.tdBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
  };
  const trBorderStyles = {
    borderBottomColor: theme
      ? `1px solid ${themeColors.trBorderColor}`
      : `1px solid ${themeColors.trBorderColorDark}`,
  };
  const thStyles = {
    backgroundColor: theme
      ? themeColors.thBackgroundColor
      : themeColors.thBackgroundColorDark,
    color: theme ? themeColors.textPrimary : themeColors.textPrimaryDark,
    padding: "1rem",
  };
  const selectInputContainerStyles = { width: "140px", marginRight: "5px" };

  useEffect(() => {
    if (isValidTillDatePassed) {
      alert("Your Leads Rubix subscription has expired, Please renew before upgrading users");
      history.push("/");
    }
  }, [])

  const handleAddEmpNumber = () => {
    updatePlan(user.organization_id);
  }

  const handleUnitsChange = (e: any) => {
    const value = parseInt(e.target.value) || 0;
    
    if (value <= noEmp) {
      setUnitsValidation(false);
      setUnits(value);
    } else {
      setUnitsValidation(true);
    }
  };
  return (
    <div className={styles.parent} style={{ ...containerStyles, marginTop: "4%" }}>
      {openModal &&
        (
          <SuccessAndFailureModal
            isSuccess={isTransactionSuccess}
            open={openModal}
            close={() => setOpenModal(false)}
          />)
      }
      <div className={styles.container}>
        <div
          className="m-auto d-flex flex-column align-items-center mt-4"
        >
          <h5 className="fw-bold">Manage your Leads Rubix Subscription</h5>
          <div className="w-100 mt-4">
            <table
              className="table shadow rounded bg-white"
              style={{ backgroundColor: "red" }}
            >
              <thead className="bg-light">
                <tr>
                  <th style={thStyles}>Item</th>
                  <th style={thStyles}>Cost</th>
                  <th style={thStyles}>Units</th>
                  <th style={thStyles}>Total Amount</th>
                </tr>
              </thead>
              {organizationData?.trial_period ? <>
                <tbody>
                  <tr className="border-bottom">
                    <td style={tdStyles}>
                      <p className="fw-bold mb-1">Leads Rubix License</p>
                      {/* <p>{units} license for {daysDifference} days <br></br> At Rs {numberWithCommas(perDayLicenseCost)}/day <br></br>From {currentDate} to {validTill}</p> */}
                    </td>
                    <td style={tdStyles}>
                      <p className="fw-bold mb-1">₹ {numberWithCommas(0)}</p>
                    </td>
                    <td style={tdStyles}>
                      {noEmp <= 0 ? <>
                        <div style={{ ...selectInputContainerStyles }}>
                          <input type="number" className="form-control"
                            disabled
                            value={1}
                            onChange={(e) => handleUnitsChange(e)}
                          />
                          <span style={{ color: "red" }}> {`User limit exhausted for trial period. Please renew to add users.`}</span>
                        </div>
                      </> : <>
                        <div style={{ ...selectInputContainerStyles }}>
                          <input type="number" min="1" className="form-control" defaultValue={units}
                            onChange={(e) => handleUnitsChange(e)}
                          />
                          {unitsValidation === true ? <>
                            <span style={{ color: "red" }}> {`Add Units less than ${noEmp}.`}</span>
                          </> : <></>}
                        </div>
                      </>}

                    </td>
                    <td style={tdStyles}>
                      <p className="fw-normal mb-1">₹ {numberWithCommas(0)}</p>
                      <p>(Prorata Amount)</p>
                    </td>
                  </tr>
                </tbody>
              </> : <>
                <tbody>
                  <tr className="border-bottom">
                    <td style={tdStyles}>
                      <p className="fw-bold mb-1">Leads Rubix License</p>
                      <p>{units} license for {daysDifference} days <br></br> At Rs {numberWithCommas(perDayLicenseCost)}/day <br></br>From {currentDate} to {validTill}</p>
                    </td>
                    <td style={tdStyles}>
                      <p className="fw-bold mb-1">₹ {numberWithCommas(costPerLicense)}</p>
                    </td>
                    <td style={tdStyles}>
                      <div style={{ ...selectInputContainerStyles }}>
                        <input type="number" min="1" className="form-control" defaultValue={units} onChange={(e) => { setUnits(e.target.value) }} />
                      </div>
                    </td>
                    <td style={tdStyles}>
                      <p className="fw-normal mb-1">₹ {numberWithCommas(amount)}</p>
                      <p>(Prorata Amount)</p>
                    </td>
                  </tr>
                </tbody>
              </>}
            </table>
          </div>
          {organizationData?.trial_period ? <>
            <h6 className="mt-2">Amount to be paid now</h6>
            <h1>₹ {numberWithCommas(0)}</h1>
            {/* <h6>₹ {numberWithCommas(amount)} + ₹ {numberWithCommas(tax)}(18% Tax)</h6> */}
            <div className="mt-4">
              <button
                className={styles.buttonSecondary}
                onClick={() => {
                  history.push("/subscriptionDetails");
                }}
              >
                Cancel
              </button>
              <button
                className={styles.button}
                disabled={unitsValidation || noEmp <= 0? true : false}
                style={{ marginLeft: "1rem" }}
                onClick={() => handleAddEmpNumber()}
              >
                Proceed
              </button>
            </div>
          </> : <>
            <h6 className="mt-2">Amount to be paid now</h6>
            <h1>₹ {numberWithCommas(totalAmount)}</h1>
            <h6>₹ {numberWithCommas(amount)} + ₹ {numberWithCommas(tax)}(18% Tax)</h6>
            <div className="mt-4">
              <button
                className={styles.buttonSecondary}
                onClick={() => {
                  history.push("/subscriptionDetails");
                }}
              >
                Cancel
              </button>
              <button
                className={styles.button}
                style={{ marginLeft: "1rem" }}
                onClick={() => { handlePayment(totalAmount, units, orgName) }}
              >
                Proceed
              </button>
            </div>
          </>}

        </div>
      </div>
      <div
        className="card px-4 pt-2 w-100 d-flex flex-row justify-content-between position-absolute bottom-0 end-0"
        style={cardContainerStyles}
      >
        <div>
          {/* <h6>accounts@itsforyou.live</h6> */}
          <p>(For payments and billing questions)</p>
        </div>
        <div>
          {/* <h6>8755964699</h6> */}
          <p>(Toll Free 24 hours - Monday to Friday)</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
    user: state.user.data,
    organizationData: state.organization.organizationData,
    authToken: state.user.authToken
  };
};

export default connect(mapStateToProps)(UpgradeUser);
