import moment from 'moment';
import { setFilterObject } from '../Redux/actions';
import Firebase from "firebase/app";
import { firestore, functions } from "../Firebase";

export const getDateString = (timeStamp: any) => {
  if (timeStamp) {
    const utcDate = moment.utc(timeStamp.toDate());
    const localDate = moment(utcDate).local();
    return localDate.format('DD MMM YY, LT');
  } else {
    return '';
  }
};

export const getDateImportString = (timeStamp: any) => {
  if (timeStamp) {
    const utcDate = moment.utc(timeStamp.toDate());
    const localDate = moment(utcDate).local();
    return localDate.format('MM/DD/YY HH:mm');
  } else {
    return '';
  }
};

export const getDiffDateString = (startDays: any,enddays:any) => {
  if (startDays && enddays) {
    const startDate:any = new Date('2023-08-18');
    const endDate:any = new Date('2023-08-23');
    const timeDiff = endDate - startDate;

// Calculate the number of days
    return Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  } else {
    return '';
  }
};

export const getDateString2 = (timeStamp: any) => {
  if (timeStamp) {
    const utcDate = moment.utc(timeStamp.toDate());
    const localDate = moment(utcDate).local();
    return localDate.format('DD MMM YYYY');
  } else {
    return '';
  }
};

export const getDateIsoString = (timeStamp: any) => {
  if (timeStamp) {
    const utcDate = moment.utc(timeStamp?.toDate());
    const localDate = utcDate.toISOString().slice(0, 10);
    return localDate;
  } else {
    return '';
  }
};

export const generatePassword = (length: any) => {
  var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$&";
  var password = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }

  return password;
}

export const getExpirationDate = (date: any, days: any) => {
  if (date && days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().slice(0, 10);
  } else {
    return '';
  }
}

export const getFormattedDate = (date: any) => {
  let newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export const addMonths = (timeStamp: any, months: any) => {
  if (timeStamp) {
    const utcDate = moment.utc(timeStamp.toDate());
    const localDate = moment(utcDate).local().format('DD MMM YYYY, LT');
    let result = new Date(localDate);
    // result.setDate(result.getDate() + days);
    // result.setMonth(result.getMonth()+months);
    return Firebase.firestore.Timestamp.fromDate(
      new Date(result.setMonth(result.getMonth() + months))
    );
  }

  //  var newDate = new Date(date.setMonth(date.getMonth()+8));
}
export const addMonthsTP = (months: any) => {
  if (months) {
    const currentTimeStamp=Firebase.firestore.Timestamp.now()
    const utcDate = moment.utc(currentTimeStamp.toDate());
    const localDate = moment(utcDate).local().format('DD MMM YYYY, LT');
    let result = new Date(localDate);
    console.log("result",result)
    return Firebase.firestore.Timestamp.fromDate(
      new Date(result.setMonth(result.getMonth() + months))
    );
  }
}
export const addDays = (timeStamp: any, days: any) => {
  if (timeStamp) {
    let result;
    if (timeStamp instanceof Firebase.firestore.Timestamp) {
      // Handle Firestore Timestamp object
      const date = timeStamp.toDate();
      date.setDate(date.getDate() + days);
      result = Firebase.firestore.Timestamp.fromDate(date);
    } else if (timeStamp instanceof Date) {
      // Handle JavaScript Date object
      const date = new Date(timeStamp);
      date.setDate(date.getDate() + days);
      result = date;
    } else {
      // Handle other timestamp formats as needed
      const date = new Date(timeStamp);
      date.setDate(date.getDate() + days);
      result = date;
    }
    return result; // Return the modified timestamp
  }
}

export const convertTimestampToDate = (timestampObject: any) => {
  const timestamp = Firebase.firestore.Timestamp.fromMillis(
    timestampObject.seconds * 1000 + timestampObject.nanoseconds / 1e6
  );
  const dateObject = timestamp.toDate();
  // Format the date as a string
  // const formattedDateString = dateObject.toISOString().slice(0, 16);
  const formattedDateString = dateObject.toLocaleString(); // Adjust the format as needed
  return formattedDateString;
};


export const addMonthsWithoutTimestamp = (timeStamp: any, months: any) => {
  if (timeStamp) {
    const utcDate = moment.utc(timeStamp.toDate());
    const localDate = moment(utcDate).local().format('DD MMM YYYY, LT');
    let result = new Date(localDate);
    // result.setDate(result.getDate() + days);
    // result.setMonth(result.getMonth()+months);
    return new Date(result.setMonth(result.getMonth() + months))
  }

  //  var newDate = new Date(date.setMonth(date.getMonth()+8));
}
export const addMonthsWithoutTimestampTP = (months: any) => {
  if (months) {
    const currentTimeStamp=Firebase.firestore.Timestamp.now()
    const utcDate = moment.utc(currentTimeStamp.toDate());
    const localDate = moment(utcDate).local().format('DD MMM YYYY, LT');
    let result = new Date(localDate);
    // result.setDate(result.getDate() + days);
    // result.setMonth(result.getMonth()+months);
    return new Date(result.setMonth(result.getMonth() + months))
  }
  //  var newDate = new Date(date.setMonth(date.getMonth()+8));
}

export const compareDates = (date1: any, date2: any) => {
  if (date1 > date2) {
    return true
  } else {
    return false
  }
}

export const getFilterObject = (
  data: { [key: string]: any }[],
  dispatcher: any
) => {
  const finalData: { [key: string]: string[] } = {};
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      let value = item[key];
      if (item[key]?.toDate) {
        value = getDateString(item[key]);
      } else if (key === 'user_first_name') {
        value = item[key] + ' ' + item['user_last_name'];
        key = 'Name';
      } else if (key === 'admin_first_name') {
        value = item[key] + ' ' + item['admin_last_name'];
        key = 'Admin Name';
      }

      if (finalData[key]) {
        if (!finalData[key].includes(value)) {
          finalData[key].push(value);
        }
      } else {
        finalData[key] = [value];
      }
    });
  });

  dispatcher(setFilterObject(finalData));
};

export const getFilterBranch = (data: { [key: string]: any }[]) => {
  const finalData: { [key: string]: string[] } = {};
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      let value = item[key];
      if (item[key]?.toDate) {
        value = getDateString(item[key]);
      } else if (key === 'user_first_name') {
        value = item[key] + ' ' + item['user_last_name'];
        key = 'Name';
      } else if (key === 'admin_first_name') {
        value = item[key] + ' ' + item['admin_last_name'];
        key = 'Admin Name';
      }

      if (finalData[key]) {
        if (!finalData[key].includes(value)) {
          finalData[key].push(value);
        }
      } else {
        finalData[key] = [value];
      }
    });
  });

  return finalData;
};

export const getDateAndTime = (timeStamp: string, format: string) => {
  const utcDate = moment.utc(timeStamp);
  const localDate = moment(utcDate).local();
  return localDate.format(format);
};

export const searchUserItem = (itemList: any[], searchQuery: string) => {
  let data = [...itemList];
  if (searchQuery.length !== 0) {
    if (searchQuery.match(/^[0-9]+$/) != null) {
      data = data.filter((item) => item.contact_no.includes(searchQuery));
    } else {
      data = data.filter((item) =>
        String(item.user_first_name + item.user_last_name)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }
  }
  return data;
};

export const searchAPIItem = (itemList: any[], searchQuery: string) => {
  let data = [...itemList];
  if (searchQuery.length !== 0) {
    data = data.filter((item) =>
      item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) || item.contact_no.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  return data;
};


export const searchColumnItem = (itemList: any[], searchQuery: string) => {
  let data = [...itemList];
  if (searchQuery.length !== 0) {
    if (searchQuery.match(/^[0-9]+$/) != null) {
    } else {
      data = data.filter((item) =>
        item.id.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  }
  return data;
};

export const searchOrganizationItem = (
  itemList: any[],
  searchQuery: string
) => {
  let data = [...itemList];
  if (searchQuery.length !== 0) {
    if (searchQuery.match(/^[0-9]+$/) != null) {
      data = data.filter((item) => item.mobile_number.includes(searchQuery));
    } else {
      data = data.filter((item) =>
        item.organization_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  }
  return data;
};

export const searchContacts = (filterData: any[], searchedItem: string) => {
  let data = [...filterData];
  if (searchedItem.match(/^[0-9]+$/) != null) {
    data = data.filter((item) => item.contact_no.includes(searchedItem));
  } else {
    data = data.filter((item) =>
      item.customer_name.toLowerCase().includes(searchedItem.toLowerCase())
    );
  }
  return data;
};

export const searchCallLogs = (filterData: any[], searchedItem: string) => {
  let data = [...filterData];
  if (searchedItem.match(/^[0-9]+$/) != null) {
  } else {
    data = data.filter((item) =>
      item.created_by.toLowerCase().includes(searchedItem.toLowerCase())
    );
  }
  return data;
};

export const searchTaskItem = (itemList: any[], searchQuery: string) => {
  let data = [...itemList];
  if (searchQuery.length !== 0) {
    data = data.filter((item) =>
      item.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  return data;
};

export const searchProjectItem = (itemList: any[], searchQuery: string) => {
  let data = [...itemList];
  if (searchQuery.length !== 0) {
    data = data.filter((item) =>
      item.project_name.toLowerCase().includes(searchQuery.toLowerCase()) || item.developer_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  return data;
};

export const filterStatus = (itemsList: any[], status: string) => {
  let data = [...itemsList];
  if (status !== 'ALL') {
    data = data.filter((item) => item.status === status);
  }
  return data;
};

export const filterTaskStatus = (itemsList: any[], status: string) => {
  let data = [...itemsList];
  if (status !== 'ALL') {
    data = data.filter((item) => item.status === status);
  }
  return data;
};

export const filterProjectStatus = (itemsList: any[], status: string) => {
  let data = [...itemsList];
  if (status !== 'ALL') {
    data = data.filter((item) => item.property_type === status);
  }
  return data;
};

export const properFormat = (name: string) => {
  if (typeof name !== 'string') {
    return name;
  }
  if (name === 'CALLBACK') {
    return 'Call Back';
  }
  return name
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');
};

export const sortAnalytics = (a: any, b: any) => {
  if (a.Total > b.Total) {
    return -1;
  } else {
    return 1;
  }
};
export const sortAnalyticsDownLoadReport = (a: any, b: any) => {
  if (a.total > b.total) {
    return -1;
  } else {
    return 1;
  }
};

export const chunk = (arr: any[], chunkSize: number) => {
  if (chunkSize <= 0) {
    return [];
  }
  var R = [];
  for (var i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
};

export const timer = (ms: number) => {
  return new Promise((res) => setTimeout(res, ms));
};


// <div>Hello,</div> <br/><div>Your account will expire on DD||MM||YYYY.</div> <br/>  <div>Either you have been very busy, or missed our reminders to you! It is not too late yet. All you have to do is pay your renewal amount using the below link and allow us to renew your subscription for uninterrupted service and support.</div>  <br/><br/>   <a href=https://login1.read-pro.com/ style={background-color:#272944;color:#fff;padding:7px;border-radius:5px;font-weight: bold;margin-bottom:10px}>Renew Now*</a><br/><br/><div> (Only Applicable for desktop) </div> <br/> <div>Please note that your account will be automatically downgraded if the amount has not been paid on or before 2022-10-20.</div>

export const sendMail = async (mail?: any, mailId?: any, subject?: any) => {
  try {
    await firestore.collection("mail").add({
      to: mailId,
      message: {
        subject: subject,
        html: mail
      },
    })
      .then(() => console.log("Queued email for delivery!"));
  }
  catch (err) {
    console.log("error", err)
  }
};