import React, { useEffect, useState } from "react";
import commonStyle from "../common.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import { deleteOrgUser, fetchOrganizations } from "../../Services/organizations";
import Loading from "../../Components/Loading/Loading";
import { ORGANIZATION_COLUMNS } from "../../Values/tables";
import { connect, useDispatch } from "react-redux";
import { filterStatus, searchOrganizationItem } from "../../Values/utils";
import styles from '../Analytics/Analytics.module.css'
import CustomTable from "../../Components/CustomTable/CustomTable";
import {
  organizationIdWithName, showSnackbarAction,
} from '../../Redux/actions';
import { themeColors } from "../../Components/theme";
import ChangePasswordModal from './PasswordChangeModel';
import { url } from "../../Values/constants";
import axios from "axios";

const Organizations = ({ history, theme }: any) => {
  const [organizationData, setOrganizationData] =
    useState<any[] | undefined>(undefined);
  const [filterData, setFilterData] = useState<any[] | undefined>(undefined);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [temporaryData, setTemporaryData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("ALL");
  const [columnModal, setColumnModal] = useState(false);
  const [load, setLoad] = useState<any>(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [rowChangePassData, setRowChangePassData] = useState<any>();
  // const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatcher = useDispatch();

  useEffect(() => {
    const unSub = fetchOrganizations((data) => setOrganizationData(data));
    return unSub;
  }, []);

  useEffect(() => {
    let organizationIdNameArr: { value: number, label: string, industryId: string }[] = [];
    if (organizationData !== undefined) {
      organizationData.filter((list: any) => list.showAnalytics === true && list.organization_name != "Optiinvest").map((list: any) => {
        organizationIdNameArr.push({
          "value": list.auth_id,
          "label": list.organization_name,
          "industryId": list.industry_id
        })
      })
      setFilterData(organizationData);
    }

    dispatcher(organizationIdWithName(organizationIdNameArr));
  }, [organizationData, dispatcher]);

  useEffect(() => {
    if (status === "ALL") {
      if (organizationData) {
        const data = searchOrganizationItem(organizationData, searchQuery);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = searchOrganizationItem(temporaryData, searchQuery);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [searchQuery, organizationData]);

  useEffect(() => {
    if (searchQuery === "") {
      if (organizationData) {
        const data = filterStatus(organizationData, status);
        setTemporaryData(data);
        setFilterData(data);
      }
    } else {
      const data = filterStatus(temporaryData, status);
      setFilterData(data);
    }
    // eslint-disable-next-line
  }, [status, organizationData]);

  const topBarStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark, width: "97%" }
  const parentStyles = { backgroundColor: theme ? themeColors.backgroundColor : themeColors.backgroundColorDark }

  const handleDeleteUserOrg = (data: any) => {
    if (window.confirm('Are you sure you want to delete this organization?')) {
      setLoad(true);
      // alert(data?.auth_id);
      deleteOrgUser(
        data?.auth_id,
        (value) => setLoad(value),
        dispatcher
      );
      deleteDataDB(data?.auth_id);
    }
  }

  const deleteDataDB=async(authId:any)=>{
    // alert(authId);
    try {
      const response = await axios.delete(
        `${url}/organizations/delete?auth_id=${authId}`
      );
      // Assuming successful deletion returns a status of 200
      if (response.status === 200) {
        // Handle success
        console.log('Organization deleted successfully',response);
      }
    } catch (error) {
      // Handle error
      console.error('Error deleting organization:', error);
    } 
  }

  const openPasswordModal = (data:any) => {
    setShowPasswordModal(true);
    setRowChangePassData(data);
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  // const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewPassword(event.target.value);
  // };

  // const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setConfirmPassword(event.target.value);
  // };

  const changePasswordUser = () => {
    if (newPassword===""){
      dispatcher(
        showSnackbarAction(
          "New Password is required!!",
          "error"
        )
      );
      return;
    }else if (confirmPassword===""){
      dispatcher(
        showSnackbarAction(
          "Confirm Password is required!!",
          "error"
        )
      );
      return;
    }
    // updateUserAuth()
    if (newPassword === confirmPassword) {
      // Perform password change action here
      updateUserAuth();
      // closePasswordModal();
    }
    else {
      dispatcher(
        showSnackbarAction(
          "Passwords do not match!!",
          "error"
        )
      );
      return;
    }
    
  };
  const updateUserAuth = async () => {
    const bodyData = {
      uid: rowChangePassData.auth_id,
      newEmail: rowChangePassData?.admin_email_id,
      newPassword: newPassword
    }
    
    try {
      const repUpdateUserAuth = await axios.post(
        `${url}/utils/firebase-updateUser`, bodyData);
      // handle response
      console.log("repUpdateUserAuth", repUpdateUserAuth);
      if(repUpdateUserAuth.status==200){
        dispatcher(
          showSnackbarAction(
            "Password changed successfully!",
            "success"
          )
        );
          closePasswordModal();
      }
    } catch (error) {
      // handle error
        closePasswordModal();
    }
  };

  return (
    <>
      <div className={styles.parent} style={{ display: "block", marginTop: "4%", ...parentStyles }}>
        <div className={commonStyle.topBar} style={{ marginTop: "0px", ...topBarStyles }}>
        {organizationData === undefined && <Loading />}
          {load && <Loading />}
          <TopBar
            history={history}
            title={"Add Organization"}
            path={"addOrganizations"}
            onChange={(val) => setSearchQuery(val)}
            setStatus={(status) => setStatus(status)}
            filterText="Status"
            status={status}
            setColumnModal={(data) => setColumnModal(data)}
            show={true}
            showStatusBox={true}
          />
        </div>
        <div className={commonStyle.parent} style={{ minHeight: "450px" }}>
          {/* {organizationData === undefined && <Loading />}
          {load && <Loading />} */}
          <CustomTable
            tableColumns={ORGANIZATION_COLUMNS}
            tableRows={filterData}
            selectedRows={selectedRows}
            setSelectedRows={(data) => setSelectedRows(data)}
            tableType="Super admin"
            showColumnModal={columnModal}
            hideColumnModal={() => setColumnModal(false)}
            setSearchedItem={(val) => setSearchQuery(val)}
            handleDeleteOrgUser={handleDeleteUserOrg}
            handleChangePassword={openPasswordModal}
          />
        </div>
      </div>
      {/* Password change modal */}
      {showPasswordModal && (
        <ChangePasswordModal
        open={showPasswordModal}
        close={closePasswordModal}
        setNewPassword={setNewPassword}
        setConfirmPassword={setConfirmPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        changePasswordUser={changePasswordUser}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(Organizations);
