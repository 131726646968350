import React, {
  FunctionComponent,
  useCallback,
  useRef,
  useEffect,
} from "react";
import styles from "./UserDetails.module.css";
import { auth } from "../../Firebase";
import { FaUserCircle } from "react-icons/fa";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Button from "@material-ui/core/Button";
import { themeColors } from "../theme";

type props = {
  openM: any;
  close?: () => void;
  user: any;
  contacts: {
    data: any;
  };
  theme: any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDetails: FunctionComponent<props> = ({
  openM,
  close,
  user,
  contacts,
  theme
}) => {
  const history = useHistory();
  const logOut = async () => {
    Object.keys(contacts.data).forEach((key) => {
      contacts.data[key] = [];
    });
    await auth.signOut();
    history.replace("/", {});
  };
  const containerStyles = { backgroundColor: theme ? themeColors.navbarBackgroundColor : themeColors.navbarBackgroundColorDark, color: theme ? "white" : themeColors.textPrimaryDark }
  // const customStyles = {
  //   content: {
  //     backgroundColor:theme ? themeColors.dropdownBackgroundColor:themeColors.dropdownBackgroundColorDark,color:theme ? themeColors.textPrimary:themeColors.textPrimaryDark
  //   },
  // };
  return (
    <>
      <Dialog
        open={openM}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={containerStyles}>
          {"Confirmation"}
        </DialogTitle>
        <DialogContent style={containerStyles}>
          <DialogContentText id="alert-dialog-slide-description" style={containerStyles}>
            Are you sure you want to Logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={containerStyles}>
          <Button onClick={close} style={{ backgroundColor: "white" }} color="secondary">
            Cancel
          </Button>
          <Button onClick={logOut} style={{ color: "#272944", backgroundColor: "white" }} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.data,
    contacts: state.contacts,
    theme: state.theme.isLightMode,
  };
};

export default connect(mapStateToProps)(UserDetails);
